<template>
    <div id="printer-form" :class="[$mq]">
        <!-- STEP -- SELECT MODEL -->
        <div class="step">
            <p class="step-title">{{ $t('config.printer.windows.config1') }}</p>
            <div class="value">
                <div class="options main">
                    <div class="models">
                        <template v-if="Object.keys(printerModels).length > 0">
                            <div v-for="tpl in printerModels" class="item model" :class="{ selected: printer.model == tpl.id }" @click="changeModel(tpl, tpl.id, tpl.name, printer.model == tpl.id, is_new)" :key="tpl.id">
                                <div class="selected"></div>
                                <img class="image" :src="tpl.image ? tpl.image : css_image_url + 'printer_cccccc.svg'" />
                                <div class="meta">
                                    <div class="name">{{ tpl.name }}</div>
                                </div>
                            </div>
                        </template>
                        <div v-else class="empty">
                            {{ $t('config.printer.model.empty') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- CONDITIONAL STEPS (DEPENDING ON THE PLATFORM OR THE CONNECTION) -->
        <template v-if="printer && printer.model">
            <!-- WIFI CONFIG -->
            <template v-if="['WIFI', 'NET'].includes(printer.mode) || (printer.mode == 'ALL' && !isApp)">
                <div class="step">
                    <div class="options main row">
                        <div class="step-title info">{{ $t('config.printer.WIFI.step_title') }}</div>
                        <div class="info-icon" @click="showWifiInfo = !showWifiInfo"></div>
                        <transition name="leftToRight">
                            <Message v-if="['landscape', 'desktop'].includes($mq) || (['portrait'].includes($mq) && showWifiInfo)" :message="$t('config.printer.WIFI.info')" type="info" :buttonLabel="$t('config.printer.see_article')" @buttonAction="gotoURL($t('config.printer.WIFI.info_url'))" :key="'wifi_1'"></Message>
                        </transition>
                        <div class="value">
                            <input class="input" type="text" v-model="printer.ip" required pattern="^([0-9]{1,3}\.){3}[0-9]{1,3}$" :placeholder="$t('config.printer.WIFI.sample')" />
                        </div>
                    </div>
                </div>
            </template>

            <!-- ANDROID CONFIG -->
            <template v-if="isApp">
                <!-- BLUETOOTH CONFIG -->
                <template v-if="printer.mode == 'BLUETOOTH'">
                    <!-- STEP -- SELECT PRINTER -->
                    <div class="step">
                        <div class="options">
                            <div class="step-title info">{{ $t('config.printer.' + printer.mode + '.step_title') }}</div>
                            <div class="info-icon" @click="showBluetoothInfo = !showBluetoothInfo"></div>
                            <transition name="leftToRight">
                                <Message v-if="['landscape', 'desktop'].includes($mq) || (['portrait'].includes($mq) && showBluetoothInfo)" :message="$t('config.printer.BLUETOOTH.config2_info')" type="info" :buttonLabel="$t('config.printer.see_article')" @buttonAction="gotoURL($t('config.printer.BLUETOOTH.config2_info_url'))" :key="'bluetooth_1'"></Message>
                            </transition>
                            <div class="value">
                                <ul class="devices list hide-scrollbar">
                                    <div class="meta">
                                        <template v-if="['landscape', 'desktop'].includes($mq)">
                                            <div class="label" v-html="$t('config.printer.BLUETOOTH.devices_sync') + ':'"></div>
                                        </template>
                                    </div>
                                    <template v-if="Object.keys(devices).length > 0">
                                        <li v-for="pt in devices" :key="pt.macAddress" class="item printer" :class="{ selected: printer.mac == pt.macAddress }" @click="selectDevice(pt)">
                                            {{ pt.name }}
                                        </li>
                                    </template>
                                    <template v-else>
                                        <li v-if="Object.keys(devices).length == 0" class="empty">
                                            {{ $t('config.printer.' + printer.mode + '.empty') }}
                                        </li>
                                    </template>

                                    <div class="btn-search" :class="{ filled: Object.keys(devices).length > 0 }" @click="searchPrinters()">{{ $t('config.printer.refresh') }}</div>
                                </ul>
                            </div>
                        </div>
                    </div>
                </template>

                <template v-if="printer.mode == 'ALL'">
                    <!-- STEP -- SELECT PRINTER -->
                    <div class="step">
                        <div class="options">
                            <div class="step-title info">{{ $t('config.printer.ZEBRA.step_title_2') }}</div>
                            <div class="value">
                                <div class="print-selector">
                                    <div
                                        class="wifi"
                                        @click="
                                            printer.print_mode = 'wifi'
                                            wifiSelected = true
                                            if (is_new) {
                                                showStepsReconfigureBluetooth = true
                                                printer.reconfigure = true
                                            }
                                        "
                                        :class="[{ selected: wifiSelected }]"
                                    >
                                        {{ $t('config.printer.WIFI.title') }}
                                    </div>
                                    <div
                                        class="bluetooth"
                                        @click="
                                            printer.print_mode = 'bluetooth'
                                            wifiSelected = false
                                            showStepsReconfigureBluetooth = false
                                            printer.reconfigure = false
                                        "
                                        :class="[{ selected: !wifiSelected }]"
                                    >
                                        {{ $t('config.printer.BLUETOOTH.title') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="step" v-if="currentSdk == 'zebra' && !showStepsReconfigureBluetooth && printer.print_mode == 'wifi'">
                        <div class="options main row">
                            <div class="step-title info">{{ $t('config.printer.ZEBRA.step_title_3') }}</div>
                            <div class="info-icon" @click="showWifiInfo = !showWifiInfo"></div>
                            <transition name="leftToRight">
                                <Message v-if="['landscape', 'desktop'].includes($mq) || (['portrait'].includes($mq) && showWifiInfo)" :message="$t('config.printer.WIFI.info')" type="info" :buttonLabel="$t('config.printer.see_article')" @buttonAction="gotoURL($t('config.printer.WIFI.info_url'))" :key="'wifi_1'"></Message>
                            </transition>
                            <div class="value">
                                <input class="input" type="text" v-model="printer.ip" required pattern="^([0-9]{1,3}\.){3}[0-9]{1,3}$" :placeholder="$t('config.printer.WIFI.sample')" />
                                <div class="description" v-if="printer.ssid" v-html="$t('config.printer.WIFI.connected_to', { s: printer.ssid })"></div>
                            </div>
                            <div class="step-input" v-if="printer.mode == 'ALL' && isApp">
                                <div
                                    class="link"
                                    @click="
                                        showStepsReconfigureBluetooth = !showStepsReconfigureBluetooth
                                        printer.reconfigure = true
                                    "
                                    v-html="$t('config.printer.BLUETOOTH.reconfigure')"
                                ></div>
                            </div>
                        </div>
                    </div>
                    <div class="step" v-else-if="currentSdk == 'brother' && printer.print_mode == 'wifi'">
                        <div class="options main row">
                            <div class="step-title info">{{ $t('config.printer.ZEBRA.step_title_3') }}</div>
                            <div class="info-icon" @click="showWifiInfo = !showWifiInfo"></div>
                            <transition name="leftToRight">
                                <Message v-if="['landscape', 'desktop'].includes($mq) || (['portrait'].includes($mq) && showWifiInfo)" :message="$t('config.printer.WIFI.info')" type="info" :buttonLabel="$t('config.printer.see_article')" @buttonAction="gotoURL($t('config.printer.WIFI.info_url'))" :key="'wifi_1'"></Message>
                            </transition>
                            <div class="value">
                                <input class="input" type="text" v-model="printer.ip" required pattern="^([0-9]{1,3}\.){3}[0-9]{1,3}$" :placeholder="$t('config.printer.WIFI.sample')" />
                                <div class="description" v-if="printer.ssid" v-html="$t('config.printer.WIFI.connected_to', { s: printer.ssid })"></div>
                            </div>
                        </div>
                    </div>
                    <div class="step" v-if="(currentSdk == 'zebra' && showStepsReconfigureBluetooth) || printer.print_mode == 'bluetooth'">
                        <div class="options">
                            <div class="step-title info">{{ $t('config.printer.ZEBRA.step_title_3_1') }}</div>
                            <div class="info-icon" @click="showBluetoothInfo = !showBluetoothInfo"></div>
                            <transition name="leftToRight">
                                <Message v-if="['landscape', 'desktop'].includes($mq) || (['portrait'].includes($mq) && showBluetoothInfo)" :message="$t('config.printer.BLUETOOTH.config2_info')" type="info" :buttonLabel="$t('config.printer.see_article')" @buttonAction="gotoURL($t('config.printer.BLUETOOTH.config2_info_url'))" :key="'all_1'"></Message>
                            </transition>
                            <div class="value">
                                <ul class="devices list hide-scrollbar">
                                    <div class="meta">
                                        <template v-if="['landscape', 'desktop'].includes($mq)">
                                            <div class="label" v-html="$t('config.printer.BLUETOOTH.devices_sync') + ':'"></div>
                                        </template>
                                    </div>
                                    <template v-if="Object.keys(devices).length > 0">
                                        <li v-for="pt in devices" :key="pt.macAddress" class="item printer" :class="{ selected: printer.mac == pt.macAddress }" @click="selectDevice(pt)">
                                            {{ pt.name }}
                                        </li>
                                    </template>
                                    <template v-else>
                                        <li v-if="Object.keys(devices).length == 0" class="empty">
                                            {{ $t('config.printer.BLUETOOTH.empty') }}
                                        </li>
                                    </template>

                                    <div class="btn-search" :class="{ filled: Object.keys(devices).length > 0 }" @click="searchPrinters()">{{ $t('config.printer.refresh') }}</div>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="step" v-if="currentSdk == 'zebra' && showStepsReconfigureBluetooth">
                        <div class="options">
                            <div class="step-title info">{{ $t('config.printer.ZEBRA.step_title_4') }}</div>
                            <div class="info-icon" @click="showBluetoothInfo = !showBluetoothInfo"></div>
                            <div class="label" v-html="$t('config.printer.WIFI.info_ssid')"></div>
                            <div class="value">
                                <input class="input" type="text" v-model="printer.ssid" required pattern="" :placeholder="$t('config.printer.WIFI.sample_ssid')" />
                                <input class="input" type="password" v-model="printer.password" required pattern="" :placeholder="$t('config.printer.WIFI.sample_password')" />
                            </div>
                        </div>
                    </div>
                </template>
            </template>

            <!-- PC CONFIG -->
            <template v-else>
                <template v-if="printer.mode == 'USB'">
                    <!-- USB CONFIG -->
                    <div class="step">
                        <div class="step-title">{{ $t('config.printer.windows.config2') }}</div>
                        <Message :message="$t('config.printer.windows.config2_info')" @buttonAction="gotoURL($t('config.printer.windows.config2_info_url'))" :buttonLabel="$t('config.printer.see_article')" type="info" :key="'pc'"></Message>
                        <div class="step-description" v-html="$t('config.printer.windows.config2_1')"></div>
                        <!-- <div class="step-description" v-html="$t('config.printer.windows.config2_2')"></div> -->
                        <div class="step-input">
                            <div class="label" v-html="$t('config.printer.windows.config2_3')"></div>
                            <input type="text" v-model="printer.path" :placeholder="$t('config.printer.windows.config2_4')" />
                        </div>
                    </div>

                    <div class="step" v-if="printer.model && printer.path">
                        <div class="step-title">{{ $t('config.printer.windows.config3') }}</div>
                        <template v-if="Object.keys(labels).length == 1">
                            <Message :message="$t('config.printer.windows.config3_warning')" :buttonLabel="$t('config.printer.windows.config3_warning_go')" @buttonAction="windowsDownloadTemplate(Object.values(labels)[0].id)" type="warning"></Message>
                        </template>
                        <template v-else>
                            <Message :message="$t('config.printer.windows.config3_warning')" type="warning"></Message>
                            <div class="templates">
                                <div class="label">{{ $t('config.printer.templates_download') }}</div>
                                <div class="template" v-for="template in labels" :key="template.id">
                                    <div class="name">{{ template.name }}</div>
                                    <div class="download" @click="windowsDownloadTemplate(template.id)">Descargar</div>
                                </div>
                            </div>
                        </template>
                    </div>
                </template>
            </template>
        </template>

        <!-- STEP -- SET CONFIGURATION NAME -->
        <div class="step" v-if="!disableSaveButton">
            <div class="options main">
                <p class="step-title">{{ $t('config.printer.config_name') }}</p>
                <div class="value">
                    <input class="input dense" @input="config_name_modifyed = true" v-model.lazy="printer.name" type="text" :placeholder="$t('config.printer.config_name_sample')" />
                </div>
            </div>
        </div>

        <div class="submit">
            <div class="btn secondary" @click="closeForm()">
                <div class="text">{{ $t('config.printer.cancel') }}</div>
            </div>
            <div class="btn" :class="{ disabled: disableSaveButton }" @click="validateConfig()">
                <div class="text">
                    <template v-if="['portrait'].includes($mq)">
                        {{ $t('menu_aux.action.save') }}
                    </template>
                    <template v-else>
                        {{ $t('config.printer.windows.config5') }}
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Message from '@/components/domain/ui/Message'
import mixin from '@/core/mixin'

export default {
    components: { Message },
    props: { is_new: { type: Boolean, default: false } },
    mixins: [mixin],
    data() {
        return {
            printer: false,

            // CONFIG NAME VARIABLES
            config_name_modifyed: false,
            config_name_used: [],

            // BLUETOOTH VARIABLES
            devices: [],
            is_searching: false,

            // PORTRAIT VARIABLES
            showBluetoothInfo: false,
            showWifiInfo: false,

            showStepsReconfigureBluetooth: false,

            wifiSelected: true
        }
    },
    computed: {
        // GENERAL COMPUTED
        isApp() {
            // return true
            return this.$store.getters['getIsApp']
        },

        // FORM COMPUTED
        disableSaveButton() {
            switch (this.printer.mode) {
                case 'BLUETOOTH':
                    if (!this.printer.model || !this.printer.mac) return true
                    break
                case 'WIFI':
                    var regIP = new RegExp(
                        '((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))'
                    )
                    if (regIP.exec(this.printer.ip) == null || !this.printer.model || !this.printer.ip) return true
                    break
                case 'USB':
                    if (!this.printer.model || this.printer.path == '') return true
                    break
                case 'ALL':
                case 'NET':
                    var regIP = new RegExp(
                        '((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))'
                    )

                    if (!this.showStepsReconfigureBluetooth && (((regIP.exec(this.printer.ip) == null || !this.printer.ip) && this.printer.print_mode == 'wifi') || !this.printer.model || (this.printer.print_mode == 'bluetooth' && this.printer.mac == 'null'))) return true
                    if (this.currentSdk == 'zebra' && this.showStepsReconfigureBluetooth && (this.printer.ssid == null || this.printer.password == null || this.printer.mac == null)) return true
                    break
                default:
                    return true
            }
            return false
        },

        // PRINTER COMPUTED
        printerModels() {
            const printerModels = this.$store.getters['printer/getModels']()
            return printerModels
        },

        currentPrinter() {
            return this.$store.getters['printer/getCurrentPrinter']
        },

        labels() {
            return this.$store.getters['printer/getLabelsByPrinterModel'](this.printer.model)
        },

        checkingStatusPrinter() {
            return this.$store.getters['printer/isCheckingStatusPrinter']
        },

        currentSdk() {
            if (this.printer && this.printer.model && this.printerModels[this.printer.model]) {
                return this.printerModels[this.printer.model].sdk
            }

            return false
        }

        // config() {}
    },
    methods: {
        configNameUsed() {
            var printers = this.$store.getters['printer/getPrinterConfig']()
            var oPrinters = {}
            for (var p in printers) {
                if (this.$store.getters.getIsApp && printers[p].platform == cordova.platformId) {
                    oPrinters[p] = printers[p]
                } else if (!this.$store.getters.getIsApp && printers[p].platform == 'windows') {
                    oPrinters[p] = printers[p]
                }
            }
            this.config_name_used = Object.values(oPrinters).map((a) => a.name.trim().toLowerCase())
        },

        checkConfigName(newSelectModel = false) {
            log('Config Names used -> ' + this.config_name_used)
            log(this.config_name_modifyed || newSelectModel)
            log(this.config_name_used.length > 0)
            log((!this.config_name_modifyed && newSelectModel) || this.printer.name != this.$store.getters['printer/getCurrentPrinterConfig'].name)
            if ((this.config_name_modifyed || newSelectModel) && this.config_name_used.length > 0 && ((!this.config_name_modifyed && newSelectModel) || this.printer.name != this.$store.getters['printer/getCurrentPrinterConfig'].name)) {
                if (newSelectModel) {
                    var counter = 2
                    var new_unique_name = this.printer.name
                    while (this.config_name_used.includes(new_unique_name.trim().toLowerCase())) {
                        new_unique_name = this.printer.name.trim() + ' (' + counter + ')'
                        counter++
                    }
                    this.printer.name = new_unique_name
                } else if (this.config_name_used.includes(this.printer.name.trim().toLowerCase())) {
                    // return this.$popup.alert({
                    //     message: this.$t('config.printer.config_name_error'),
                    //     textSave: this.$t('label.printer.alert_save'),
                    //     callSave: function() {
                    //         return false
                    //     }
                    // })
                    return false
                }
            }
            return true
        },

        changeModel(model, tpl, modelName, isSelected, isNew) {
            log('changeModel', model, tpl, modelName, isSelected, isNew)
            var bIsNew = typeof isNew === 'undefined' ? false : isNew
            var printer = { ...this.printer }
            log(JSON.parse(JSON.stringify(printer)))
            if (isSelected) {
                printer.model = false
                // printer.name = this.config_name_modifyed ? printer.name : ''
                printer.mode = false
            } else {
                // if (!this.isApp && model.sdk == 'zebra') {
                //     this.$popup.alert({ message: this.$t('config.printer.notavailable_pc') })
                // } else {
                printer.model = model.id
                if (!bIsNew) {
                    printer.name = this.config_name_modifyed ? printer.name : modelName
                } else {
                    printer.name = modelName ? modelName : ''
                    if (printer.name) this.checkConfigName(true)
                }
                if (this.isApp) {
                    switch (model.sdk) {
                        case 'brother':
                            printer.mode = this.printerModels[printer.model].config && this.printerModels[printer.model].config.connection ? this.printerModels[printer.model].config.connection : 'BLUETOOTH'
                            if (printer.mode == 'ALL' && !printer.print_mode) printer.print_mode = 'bluetooth'
                            if (printer.print_mode == 'bluetooth') this.wifiSelected = false
                            break

                        case 'zebra':
                            printer.mode = this.printerModels[printer.model].config && this.printerModels[printer.model].config.connection ? this.printerModels[printer.model].config.connection : 'WIFI'
                            if (printer.mode == 'ALL' && !printer.print_mode) printer.print_mode = 'wifi'
                            printer.platform = this.$store.getters.getIsAndroid ? 'android' : 'ios'
                            if (bIsNew) {
                                this.showStepsReconfigureBluetooth = true
                                printer.reconfigure = true
                            } else {
                                printer.reconfigure = false
                            }
                            break
                    }
                } else {
                    switch (model.sdk) {
                        case 'brother':
                            printer.mode = 'USB'
                            break

                        case 'zebra':
                            printer.mode = this.printerModels[printer.model].config && this.printerModels[printer.model].config.connection ? this.printerModels[printer.model].config.connection : 'WIFI'
                            if (printer.mode == 'ALL' && !printer.print_mode) printer.print_mode = 'wifi'
                            break
                    }
                }
                if (bIsNew) {
                    printer.path = ''
                }
                // }
            }
            this.printer = printer
            log(JSON.parse(JSON.stringify(this.printer)))
            if (!isSelected) this.checkConfigName(true)
        },

        selectDevice(device) {
            this.printer.mac = device.macAddress
        },

        validateConfig() {
            if (!this.checkingStatusPrinter) {
                var self = this
                this.$loader.loading()

                var hasErrors = false
                var error_msg = ''

                // COMMON CASES:
                // CHECK IF CONFIG HAS A VALID CONFIG NAME
                if (!this.printer.name || this.printer.name == '' || !this.checkConfigName()) {
                    error_msg = self.$t('config.printer.config_name_error')
                    hasErrors = true
                }

                // SPECIAL CASES:
                // CHECK IF THE LABEL TEMPLATES ARE DOWNLOADED AND ADDED TO THE INDICATED ROUTE CORRECTLY
                if (!hasErrors && !this.isApp) {
                    this.$store.commit('printer/setPrinterConfig', { ...this.printer })
                    if (this.printerModels[this.printer.model].sdk == 'brother') {
                        this.$store.dispatch('printer/validateBpac', this.printer.id).then(function (response) {
                            if (!response.status) {
                                self.cleanPrinterConfigChanges()
                                var msg = self.$t(response.msg)
                                msg = msg.replace('[path]', self.printer.path)
                                self.$snackbar.error({ duration: 8000, closeable: true, message: msg })
                                self.$loader.hide()
                            } else self.updatePrinter()
                        })
                    } else self.updatePrinter()
                }
                // COMMON FLOW
                else if (hasErrors) {
                    if (error_msg != '') {
                        this.$snackbar.error({ duration: 8000, closeable: true, message: error_msg })
                    }
                    this.$loader.hide()
                } else this.updatePrinter()
            } else {
                this.$snackbar.info({ duration: 8000, closeable: true, message: this.$t('config.printer.errors.busy2') })
            }
        },

        updatePrinter() {
            var self = this

            if (this.currentSdk == 'brother' && this.printer.print_mode == 'wifi') this.printer.mode = 'NET'
            if (this.currentSdk == 'brother' && this.printer.print_mode == 'bluetooth') this.printer.mode = 'BLUETOOTH'

            this.$store.commit('printer/setPrinterConfig', { ...this.printer })
            this.$store
                .dispatch('printer/sync', this.printer.id)
                .then(function (response) {
                    // if (typeof self.config[self.currentPrinter] == 'undefined' && Object.keys(self.config).length > 0) {
                    //     self.$store.commit('printer/setCurrentPrinter', Object.values(self.config)[0].id)
                    // }
                    self.$emit('setFirstCurrent')
                    self.$store.commit('printer/setProcessingTemplates', false)
                    self.$loader.hide()
                    self.$emit('hideForm')
                })
                .catch(function (error) {
                    log('ERROR - updatePrinter', error)
                    self.$loader.hide()
                    self.$store.commit('printer/setProcessingTemplates', false)
                    self.cleanPrinterConfigChanges()
                    if (error && error.code) {
                        self.$snackbar.error({ closeable: true, message: self.$t(error.msg, { s: error.code }) })
                    } else if (error) {
                        self.$snackbar.error({ closeable: true, message: self.$t(error.msg) })
                    } else {
                        self.$snackbar.error({ closeable: true, message: '' })
                    }
                })
        },

        cleanPrinterConfigChanges() {
            log('closeForm, clean store -->  is_new: ', this.is_new)
            //We need to clean data modified on store before leave
            if (this.is_new) {
                //New configuration, just try to remove from current configuration
                this.$store.commit('printer/deletePrinterConfig', this.printer.id)
            } else {
                //Edit configuration, we need to get copy of initial configuration and set on store
                var copy = Object.assign({}, this.$store.getters['printer/getCopyCurrentPrinterConfig'])
                this.$store.commit('printer/setPrinterConfig', copy)
            }
        },

        closeForm() {
            this.cleanPrinterConfigChanges()
            window.scrollTo({ top: 0, behavior: 'smooth' })
            this.$emit('hideForm')
        },

        gotoURL(url) {
            if (url) window.open(url, '_blank')
        },

        // CUSTOM METHODS
        // Method to get bluetooth devices available
        searchPrinters(init = false) {
            if (process.env.VUE_APP_IS_APP == 'TRUE') {
                this.is_searching = true
                var self = this
                // IF USER PRESS THE REFRESH BUTTON
                if (!init) {
                    self.$overlay.loading()
                    setTimeout(function () {
                        self.$overlay.hide()
                        clearTimeout()
                    }, 1000)
                }

                cordova.plugins.brotherprinter.getPrinters(function (aPrintersData) {
                    self.is_searching = false
                    self.devices = aPrintersData.data
                })
            }
        },

        // Method to download template on PC
        windowsDownloadTemplate(label_id = false) {
            if (label_id) {
                var label_path = this.labels[label_id].tpl_file_windows_url ? this.labels[label_id].tpl_file_windows_url : process.env.VUE_APP_API_URL + 'static/printer/' + this.labels[label_id].tpl_file_windows
                window.open(label_path, '_blank')
            }
        }
    },
    created() {
        this.searchPrinters()
    },
    mounted() {
        var printer = { ...this.$store.getters['printer/getCurrentPrinterConfig'] }
        log(JSON.parse(JSON.stringify(printer)))
        this.printer = printer
        this.configNameUsed()
        if (!this.is_new) {
            this.config_name_modifyed = printer.name != this.printerModels[printer.model].name
            this.printer.reconfigure = false
        } else {
            if (Object.values(this.printerModels).length > 0) {
                let tpl = Object.values(this.printerModels)[0]
                this.changeModel(tpl, tpl.id, tpl.name, false, true)
            }
        }

        document.getElementById('printer-form').scrollIntoView({ behavior: 'smooth' })
    },
    watch: {
        // 'printer.name'(newVal, oldVal) {
        // if (this.printer && this.printer.name && newVal != '') {
        //     this.checkConfigName(oldVal == '' ? true : false)
        // }
        // }
    }
}
</script>

<style lang="scss" scoped>
#printer-form {
    width: 90%;
    margin: 0 auto;
    max-width: 900px;
    margin-top: 50px;

    .row {
        margin-bottom: 60px;
    }

    .step-title {
        @include font-size(l);
        font-family: $text-bold;
        text-align: center;
        color: $neutro-s90;
        margin-bottom: 15px;
        line-height: initial;
    }

    .btn {
        @include interaction();
        @include background($main);
        @include border-radius(6px);
        height: 40px;
        width: 150px;
        margin: 0 auto;

        .text {
            @include font-size(m);
            @include display-flex();
            @include justify-content();
            @include align-items();
            font-family: $text-bold;
            text-align: center;
            color: #fff;
            height: 100%;
        }
    }

    .step-description {
        @include font-size(m);
        color: $neutro-s70;
        font-family: $text;
        margin: 9px auto;
        max-width: 700px;
        line-height: initial;
    }

    .step-input {
        margin-top: 20px;

        .label {
            @include font-size(m);
            color: $neutro-s70;
            width: 50vw;
            max-width: 600px;
            margin: 5px auto;
            font-family: $text-bold;
        }

        .link {
            @include font-size(xs);
            color: $main-dark;
            margin: 5px auto;
            text-align: center;
            cursor: pointer;
        }

        input {
            @include border-radius(4px);
            @include font-size(m);
            font-family: $text;
            color: $neutro-s70;
            width: 50vw;
            max-width: 600px;
            display: block;
            margin: 0 auto;
            border: none !important;
            min-height: 40px;
            padding: 3px 12px;
            cursor: text;

            &::placeholder {
                @include font-size(m);
                color: $neutro-s30;
                font-family: $text;
                font-style: normal;
            }
        }
    }

    .submit {
        @include display-flex();
        @include justify-content();
        @include align-items();
        width: fit-content;
        margin: 0 auto;

        .btn {
            @include display-flex();
            margin: 0 12px;
            width: fit-content;
            padding: 9px 12px;

            &.secondary {
                background-color: $main-t80;

                .text {
                    color: $main;
                }
            }

            &.disabled {
                @include opacity(0.4);
            }
        }
    }

    .step {
        @include display-flex();
        @include justify-content();
        @include flex-wrap(wrap);
        margin-bottom: 60px;
        min-height: 60px;

        .templates {
            @include border-radius(6px);
            margin-top: 10px;
            width: 50vw;
            max-width: 600px;
            padding: 15px 20px;
            background-color: #ffffff;

            .label {
                @include font-size('sm');
                font-family: $text;
                display: block;
                color: $neutro-s70;
                margin-bottom: 10px;
            }

            .template {
                display: block;
                width: 100%;
                overflow: hidden;
                margin: 9px 0;
                .name {
                    @include font-size('m');
                    float: left;
                    font-family: $text-bold;
                    color: $neutro-s70;
                }
                .download {
                    @include interaction();
                    @include font-size('m');
                    color: $main-s10;
                    float: right;
                    font-family: $text-bold;
                    text-decoration-line: underline;
                }
            }
        }

        .models {
            @include display-flex();
            @include justify-content();
            @include align-items();
            width: 100%;

            .model {
                @include interaction();
                @include border-radius(6px);
                width: 185px;
                overflow: hidden;
                display: inline-block;
                padding: 9px;
                margin: 10px 10px;
                position: relative;
                height: fit-content;
                background-color: darken($default-bg-color, 5%);
                // background-color: $default-content-color;

                .image {
                    @include border-radius(6px);
                    height: 70px;
                    width: 70px;
                    margin: 0 auto;
                    display: block;
                    padding: 5px;
                }
                .meta {
                    @include font-size(m);
                    overflow: hidden;
                    width: 100%;
                    text-align: center;
                    padding: 10px;
                    height: auto;
                    color: $neutro-s70;

                    div {
                        @include display-flex();
                        @include justify-content();
                        @include align-items();
                        @include text-ellipsis($line: 2);
                        width: 100%;
                        height: 100%;
                        color: inherit;
                        text-align: center;
                    }

                    .name {
                        font-family: $text-bold;
                    }

                    .tpl {
                        font-family: $text;
                    }
                }
                .selected {
                    @include background($image: img('unchecked_filled.svg'), $size: 20px, $position: center center);
                    position: absolute;
                    top: 9px;
                    left: 9px;
                    z-index: 10;
                    height: 20px;
                    width: 20px;
                    display: inherit;
                }

                &.selected {
                    background-color: #fff;

                    .selected {
                        @include background($image: img('checked_main.svg'), $size: 20px, $position: center center);
                    }

                    .meta {
                        font-family: $text-bold;
                        color: $main;
                    }
                }
            }
        }

        .devices {
            width: 100%;
            max-width: 350px;
            margin: 0 auto;
            margin-top: 25px;
            max-height: 600px;
            overflow: auto;

            .meta {
                @include display-flex();
                @include justify-content();
                @include align-items();
                margin-bottom: 10px;
                width: 100%;
                text-align: center;

                .label {
                    @include display-flex();
                    @include justify-content();
                    @include align-items();
                    height: 30px;
                }
            }
            .btn-search {
                @include border-radius(3px);
                @include display-flex();
                @include justify-content();
                @include align-items();
                @include font-size(m);
                @include background($image: img('reload_main_s30.svg'), $size: 20px, $position: left 10px center);
                background-color: $neutro;
                height: 40px;
                cursor: pointer;
                padding: 6px 9px;
                padding-left: 40px;
                margin: 15px auto 0;
                width: fit-content;
                font-family: $text;
                color: $main-s50;

                &.filled {
                    background-image: img('reload_neutro_s70.svg');
                    background-color: transparent;
                    color: $neutro-s70;
                }
            }

            .item {
                @include background($image: img('unchecked_filled.svg'), $size: 20px, $position: left 10px center);
                @include border-radius(6px);
                @include interaction();
                @include font-size(m);
                padding: 10px 15px;
                color: $neutro-s70;
                width: 100%;
                margin: 10px auto;
                font-family: $text-bold;
                background-color: #ece1e0;
                padding-left: 40px;

                &.selected {
                    @include background($image: img('checked_main.svg'), $size: 20px, $position: left 10px center);
                    background-color: #fff;
                    color: $main-dark;
                }
            }
        }

        &.disabled {
            @include opacity(0);
            visibility: hidden;
        }

        .title {
            @include font-size(16, $important: true);
            font-family: $text;
            color: #444;
            margin: 0;
            margin-bottom: 6px;
            padding: 0;

            &:before {
                @include font-size(18);
                content: '';
                color: $main-s20;
                font-family: $text;
                display: inline-block;
                width: 24px;
            }
            &:nth-of-type(1):before {
                content: '1. ';
            }
            &:nth-of-type(2):before {
                content: '2. ';
            }
            &:nth-of-type(3):before {
                content: '3. ';
            }
            &:nth-of-type(4):before {
                content: '4. ';
            }
        }

        .empty {
            @include font-size(16);
            padding: 0 6px;
            text-align: center;
            background-color: transparent;
        }

        .value {
            @include display-flex();
            @include align-items();
            @include flex-wrap(wrap);
            width: calc(100% - 24px);
            margin-left: 24px;
            // margin-bottom: 24px;
            padding: 0 0 6px 6px;

            &.disabled {
                @include opacity(0.3);
                filter: grayscale(100%);
            }

            .description {
                font-size: 14px;
                color: $neutro-s80;
                margin: 5px auto;
            }

            .change,
            .input {
                @include border-radius(4px);
                @include font-size(m);
                display: inline-block;
                margin: 0;
                font-family: $text;
                border: none !important;
                min-height: 40px;
                padding: 3px 12px;
                cursor: text;

                &::placeholder {
                    @include font-size(m);
                    color: $neutro-s30;
                    font-family: $text;
                    font-style: normal;
                }
            }

            .change {
                @include interaction();
                margin-left: 9px;
                color: $main-text;
            }
            .input {
                width: 100%;
                max-width: 400px;
                margin: 0 auto;
                margin-top: 25px;
                cursor: text;

                &.dense {
                    margin-top: 0;
                }
            }

            .btn {
                @include font-size(16);
                background-color: $main-t70;
                color: $main-text;
                padding: 8px;
                margin: 6px;
                display: block;

                &.sync {
                    margin: 0 auto;
                    padding: 20px 30px;
                    margin-top: 25px;
                }
            }
        }

        .options.main {
            max-width: calc(100% - 150px);
            width: 65%;
            overflow: auto;

            max-width: 100%;
            width: 100%;
        }

        .options.secondary {
            min-width: 150px;
            width: 35%;
            background-color: #efefef;
            padding-top: 0;

            .cancel {
                @include background($image: img('close_dark.svg'), $position: right 6px center, $size: 12px);
                line-height: 40px;
                font-family: $text;
                color: #555;
                display: block;
                padding: 0 22px 0 6px;
                margin-bottom: 12px;
                width: 100%;
                height: 40px;
                border-bottom: 1px solid #ddd;
            }

            .list {
                .item {
                    @include display-flex();
                    @include align-items();
                    @include flex-wrap(wrap);
                    @include text-ellipsis();
                    @include font-size(16);
                    @include background($position: left 6px center, $size: 18px);
                    padding-left: 35px;
                    padding: 4px 35px;
                    width: auto;
                    height: auto;
                    min-height: 40px;
                    margin: 2px 0;
                    border-left: 3px solid transparent;

                    > span {
                        display: block;
                        color: inherit;
                    }
                    > span:nth-child(1) {
                        @include font-size(14);
                        color: #555;
                    }

                    &:after {
                        @include font-size(15);
                        padding-left: 10px;
                    }
                    &.selected {
                        color: #222;
                        background-color: #f9f9f9;
                        border-color: $accent;
                    }

                    &.printer {
                        background-image: img('printer_999999.svg');
                    }
                    &.printer.selected {
                        background-image: img('printer_complem.svg');
                    }

                    &.model {
                        background-image: img('ticket_999999.svg');
                    }
                    &.model.selected {
                        background-image: img('ticket_complem.svg');
                    }
                }
            }
        }
    }

    .print-selector {
        @include display-flex();
        @include justify-content(space-between);
        @include align-items(center);
        @include border-radius(4px);
        width: 100%;
        max-width: 500px;
        height: 40px;
        padding: 3px;
        background-color: #fff;
        margin: 0 auto;
        margin-bottom: 24px;
        .wifi,
        .bluetooth {
            @include display-flex();
            @include justify-content(center);
            @include align-items(center);
            @include border-radius(4px);
            @include font-size(sm);
            font-family: $text;
            color: $neutro-s50;
            width: 50%;
            text-align: center;
            height: 34px;
            cursor: pointer;

            &.selected {
                font-family: $text-sbold;
                background-color: $main-t80;
                color: $main-dark;
                cursor: default;
            }
            &:hover:not(.selected) {
                @include bgHover(#fff);
            }
            &.loading {
                @include background($image: img('loader_dark.svg'), $position: right center, $size: 20px, $color: $main-t80);
                pointer-events: none;
            }
            &.disabled {
                pointer-events: none;
                cursor: not-allowed;
            }
        }
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss" scoped>
#printer-form.portrait {
    margin-top: 10px;
    max-width: none;
    width: 100%;
    padding: 10px;
    .step {
        .options {
            width: 100%;
        }
        .step-title {
            @include display-inline-flex();
            @include font-size(sm);
            text-align: left;
            width: 100%;

            &.info {
                width: calc(100% - 50px);
            }
        }

        .info-icon {
            @include display-inline-flex();
            @include background($image: img('info_second.svg'), $position: right 6px center, $size: 30px);
            // height: 2.4rem;
            height: 30px;
            width: 40px;
            float: right;
            cursor: pointer;
        }

        .step-description {
            @include font-size(s);
        }

        .step-input {
            width: 100%;

            .label {
                @include font-size(s);
                width: 100%;
            }
            input {
                @include font-size(s);
                width: 100%;
                margin: 0;
                line-height: 16px;
            }
        }

        .templates {
            width: 100%;
            .label {
            }

            .template {
                .name {
                    @include font-size(xs);
                }
                .download {
                    @include font-size(s);
                }
            }
        }

        .value {
            margin-left: 0;
            width: 100%;

            .options {
                width: 100%;
                .models {
                    @include justify-content();
                    @include align-items(stretch);
                    flex-basis: fit-content;
                    @include flex-wrap(wrap);
                    width: 100%;

                    .item {
                        @include display-flex();
                        @include align-items(stretch);
                        @include flex-wrap(wrap);
                        width: 130px;
                        height: 150px;

                        .selected {
                            z-index: 0;
                        }

                        .meta {
                            @include display-flex();
                            padding-left: 0;
                            padding-right: 0;
                            @include font-size(s);

                            .name {
                                @include font-size(xs);
                                @include display-flex();
                                @include align-items();
                                @include justify-content();
                            }
                        }
                    }
                }
            }

            .devices {
                .meta {
                    .label {
                        @include font-size(s);
                        height: fit-content;
                    }
                }
                .btn-search {
                    @include font-size(s);
                }
            }
        }
    }

    .submit {
        @include display-flex();
        @include align-items();
        @include justify-content(space-around);
        @include background-gradient(70%, $default-bg-color);
        position: fixed;
        bottom: 0;
        left: 0;
        width: calc(100% - #{$scroll-width});
        padding: 20px 10px;
        height: 60px;
        overflow: hidden;

        .btn {
            @include display-flex();
            @include align-items();
            @include text-ellipsis();
            max-width: 50%;
            width: calc(50% - 10px);
            margin: 0;

            .text {
                // @include display-flex();
                @include text-ellipsis();
                @include font-size(s);
                width: 100%;
                max-width: 100%;
                display: block;
            }
        }
    }
}
</style>

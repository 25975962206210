<template>
    <div class="form-container hide-scrollbar" :class="[$mq]">
        <div class="form-title">
            {{ userObject ? $t('config.users.edit') : $t('config.users.create') }}
        </div>
        <div class="form-content">
            <div class="field-form">
                <div class="label">
                    {{ $t('config.users.name') }}
                </div>

                <input type="text" v-model="user.name" class="input" :class="{ error: errors.name }" />
            </div>
            <div class="field-form">
                <div class="label">
                    {{ $t('config.users.surname') }}
                </div>

                <input type="text" :class="{ error: errors.surname }" v-model="user.surname" />
            </div>
            <div class="field-form" v-if="Object.values(roles).length > 0">
                <div class="label">
                    {{ $t('config.users.role') }}
                </div>

                <select :disabled="employeeLogged.id == userObject.id" v-model="user.rol" class="select" :class="[{ active: selectOpened }, { error: errors.rol }, { disabled: employeeLogged.id == userObject.id }]" @click="selectOpened = !selectOpened">
                    <option v-for="rol in roles" :key="rol.id" :value="rol.id">{{ rol.name }}</option>
                </select>
            </div>
            <div class="field-form">
                <div class="label">
                    {{ $t('config.users.pin') }}
                </div>

                <div class="password-container" v-if="user.password ? user.password.toString().length < 5 : true">
                    <input
                        min="0"
                        type="number"
                        @change="
                            () => {
                                if (user.password.toString().length > 4 || user.password.toString().length < 0) {
                                    this.user.password = this.user.password.toString().slice(0, 4)
                                }
                            }
                        "
                        oninput="validity.valid||(value='');"
                        :class="{ error: errors.password }"
                        v-model="user.password"
                        class="password"
                    />
                    <div class="random-password" @click="fourRandomNumbers()"></div>
                </div>
                <div class="reset" v-else>
                    <div class="btn cancel" @click="changePass()">{{ $t('config.user.change_pin') }}</div>
                </div>
            </div>
        </div>
        <div class="footer-actions">
            <div v-if="userObject" class="btn remove" @click="removeUser()"><img class="icon" src="~@/../public/img/delete_alert.svg" /> {{ $t('label.print.remove_user') }}</div>
            <div class="btns-actions">
                <div class="btn cancel" @click="cancel()">{{ $t('tickets.cancel') }}</div>
                <div class="btn" @click="validateUser()">{{ $t('label.print.save') }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import Message from '@/components/domain/ui/Message'

export default {
    components: {
        Message
    },
    props: {
        userObject: {
            type: undefined,
            default: false
        },
        employeeLogged: {
            type: undefined,
            default: false
        }
    },
    name: 'UsersForm',
    data() {
        return {
            user: {
                name: '',
                surname: '',
                rol: '',
                password: ''
            },
            errors: {
                name: false,
                surname: false,
                rol: false,
                password: false
            },
            selectOpened: false
        }
    },
    computed: {
        roles() {
            var roles = Object.values(this.$store.getters['employee/getAllRoles'])
            var result = []
            for (var i = 0; i < roles.length; i++) {
                if (roles[i].level <= 3) {
                    result.push(roles[i])
                }
                if (this.employeeLogged) {
                    if (this.employeeLogged.id == this.userObject.id && this.employeeLogged.rol == roles[i].id) {
                        result.push(roles[i])
                    }
                }
            }
            result = result.sort(function (a, b) {
                return a.level - b.level
            })
            return result
        },
        employeeEdit() {
            if (this.userObject) {
                return this.$store.getters['employee/getEmployee'](this.userObject.id)
            }
        }
    },
    methods: {
        cancel() {
            var self = this
            // Añadir nuevo usuario
            if (!this.userObject) {
                var user = {
                    name: '',
                    surname: '',
                    rol: Object.values(this.roles)[0].id,
                    password: ''
                }
                // Comprobar si ha hecho cambios en el formulario de añdir
                if (JSON.stringify(this.user) !== JSON.stringify(user)) {
                    self.$popup.confirm({
                        message: self.$t('config.users.cancel_add'),
                        textSave: self.$t('popup.label_print.continue'),
                        textCancel: self.$t('register.cancel_complete'),
                        callSave: function () {
                            self.$popup.close()
                        },
                        callCancel: function () {
                            self.$emit('cancel')
                            self.$popup.close()
                        }
                    })
                } else {
                    this.$emit('cancel')
                }
                // Comprobar que al editar usuario ha hecho cambios
            } else if (JSON.stringify(this.user) !== JSON.stringify(this.employeeEdit) && this.userObject) {
                self.$popup.confirm({
                    message: self.$t('config.users.cancel_add'),
                    textSave: self.$t('popup.label_print.continue'),
                    textCancel: self.$t('register.cancel_complete'),
                    callSave: function () {
                        self.$popup.close()
                    },
                    callCancel: function () {
                        self.$emit('cancel')
                        self.$popup.close()
                    }
                })
            } else {
                this.$emit('cancel')
            }
        },
        changePass() {
            this.user.password = ''
        },
        fourRandomNumbers() {
            var numbers = []
            for (var i = 0; i < 4; i++) {
                numbers.push(Math.floor(Math.random() * 10))
            }
            return (this.user.password = numbers.join(''))
        },

        removeUser() {
            var self = this
            self.$popup.delete({
                message: self.$t('popup.confirm.remove_user', { name: self.user.name }),
                textSave: self.$t('popup.confirm.delete_user'),
                textCancel: self.$t('popup.confirm.text_cancel'),
                callSave: function () {
                    self.$overlay.loading()
                    self.$store
                        .dispatch('employee/removeEmployee', { id: self.userObject.id })
                        .then(function () {
                            self.$popup.close()
                            self.$overlay.hide()
                            self.$snackbar.success({ duration: 3000, closeable: true, message: self.$t('config.users.remove_success', { name: self.user.name }) })
                            self.$emit('cancel')
                        })
                        .catch((error) => {
                            logError(error)
                            self.$overlay.hide()
                        })
                },
                callCancel: function () {
                    self.$popup
                        .close()

                        .catch((error) => {
                            logError(error)
                            self.$overlay.hide()
                        })
                }
            })
        },

        validateUser() {
            if (fnCheckConnection()) {
                var user = this.user
                if (user.name == '') {
                    this.errors.name = this.$t('config.users.name_required')
                } else {
                    this.errors.name = false
                }
                // if (user.surname == '') {
                //     this.errors.surname = this.$t('config.users.surname_required')
                // } else {
                //     this.errors.surname = false
                // }
                if (user.rol == '') {
                    this.errors.rol = this.$t('config.users.role_required')
                } else {
                    this.errors.rol = false
                }
                if (user.password.toString().length < 4) {
                    this.errors.password = this.$t('config.users.pin_required')
                } else {
                    this.errors.password = false
                }
                if (Object.values(this.errors).filter((error) => error != false).length > 0) {
                    this.$snackbar.error({
                        duration: 10000,
                        closeable: true,
                        message: '<ul>' + Object.values(this.errors).filter(Boolean).join(' .', this.errors) + '</ul>'
                    })
                } else {
                    this.saveEmployee()
                }
            } else {
                this.$snackbar.error({ duration: 10000, closeable: true, message: this.$t('config.users.no_internet') })
            }
        },
        saveEmployee() {
            var self = this
            self.$overlay.show()
            if (this.userObject) {
                if (this.user.password.length == 4) {
                    this.user.pin = sha256(this.user.password)
                    delete this.user.password
                } else {
                    this.user.pin = this.user.password
                    delete this.user.password
                }
                this.$store.dispatch('employee/editEmployee', this.user).then(function (response) {
                    if (response) {
                        self.$emit('cancel')
                        self.$overlay.hide()
                        self.$snackbar.success({ duration: 3000, closeable: true, message: self.$t('config.users.edit_success', { name: self.user.name }) })
                    }
                })
            } else {
                this.$store.dispatch('employee/addNewEmployee', this.user).then(function (response) {
                    if (response) {
                        self.$emit('cancel')
                        self.$overlay.hide()
                        self.$snackbar.success({ duration: 3000, closeable: true, message: self.$t('config.users.add_success', { name: self.user.name }) })
                    }
                })
            }
        }
    },
    mounted() {
        if (this.userObject) {
            this.user = this.userObject
        }
    },
    created() {
        // ASSIGN FIRST LVL 2 ROLE, IF NOT, ASSIGN FIRST ROLE
        if (!this.userObject && this.user.rol == '' && this.roles && Object.values(this.roles).length > 0) {
            var role = this.roles.filter((role) => {
                return role.level == 2
            })

            this.user.rol = role && role[0] ? role[0].id : Object.values(this.roles)[0].id
        }
    }
}
</script>

<style lang="scss" scoped></style>

<template>
    <div class="control-panel-container" :class="[$mq]">
        <div class="dashboard-title">{{ $t('config.dashboard.title') }}</div>
        <div class="background-hooper">
            <hooper :settings="hooperSettings">
                <slide>
                    <div class="images img1">
                        <div class="icon-top"></div>
                        <div class="middle-title">{{ $t('config.dashboard.employee_management') }}</div>
                        <div class="description">{{ $t('config.dashboard.employee_management_desc') }}</div>
                    </div>
                </slide>
                <slide v-if="tools.includes(3)">
                    <div class="images img2">
                        <div class="icon-top"></div>
                        <div class="middle-title">{{ $t('config.dashboard.label_history') }}</div>
                        <div class="description">{{ $t('config.dashboard.label_history_desc') }}</div>
                    </div>
                </slide>
                <slide v-if="tools.includes(3)">
                    <div class="images img4">
                        <div class="icon-top"></div>
                        <div class="middle-title">{{ $t('config.dashboard.notify_history') }}</div>
                        <div class="description">{{ $t('config.dashboard.notify_history_desc') }}</div>
                    </div>
                </slide>
                <slide v-if="tools.includes(1)">
                    <div class="images img3">
                        <div class="icon-top"></div>
                        <div class="middle-title">{{ $t('config.dashboard.checklist_doing') }}</div>
                        <div class="description">{{ $t('config.dashboard.checklist_doing_desc') }}</div>
                    </div>
                </slide>
                <hooper-pagination slot="hooper-addons"></hooper-pagination>
            </hooper>
        </div>
        <div class="messages">
            <Message v-if="employeeLogged.dashboard_access == -1 || employeeLogged.dashboard_access == 0 || employeeLogged.dashboard_access == 2" :message="$t('config.dashboard.no_access_dashboard')" type="info"></Message>
            <!-- <Message v-if="isApp() && employeeLogged.dashboard_access == 1" :message="$t('config.dashboard.is_app')" type="info"></Message> -->
        </div>
        <div class="credentials" v-if="employeeLogged.dashboard_access == 1">
            <div class="title-credentials">
                {{ $t('config.dashboard.access_desc') }}
            </div>
            <div class="link-access">
                <div class="link-access text">{{ $t('config.dashboard.access') }}</div>

                <div class="link">{{ descriptionParse(dashboardLink) }}</div>
            </div>
            <div class="email-credential">
                <div class="email-credential text">{{ $t('config.dashboard.access_email') }}</div>

                <div class="email">{{ employeeLogged.email }}</div>
            </div>
        </div>
        <div class="btn" :class="{ disabled: descriptionParse(dashboardLink) == '-' }" @click="goToDashboard()" v-if="employeeLogged.dashboard_access == 1">{{ $t('config.dashboard.enter') }}</div>
    </div>
</template>

<script>
import Vue from 'vue'
import { Hooper, Slide, Pagination as HooperPagination } from 'hooper'
import 'hooper/dist/hooper.css'

Vue.use(Hooper)
Vue.use(Slide)
Vue.use(HooperPagination)

Vue.component('hooper', Hooper)
Vue.component('slide', Slide)
Vue.component('hooper-pagination', HooperPagination)

import Message from '@/components/domain/ui/Message'

export default {
    components: { Message },
    name: 'Control_Panel_Config',
    data() {
        return {
            hooperSettings: {
                infiniteScroll: true,
                centerMode: true,
                autoPlay: true,
                playSpeed: 10000
            }
        }
    },
    computed: {
        employeeLogged() {
            var idEmployee = this.$store.getters['loginUser/getLocalEmployee']
            return this.$store.getters['employee/getEmployee'](idEmployee)
        },
        dashboardLink() {
            return this.$store.getters['login/getCPUrl']
        },
        tools() {
            var visible = []
            const config = this.$store.getters['login/getConfig']
            if (config.tools) {
                visible = config.tools
            }
            return visible
        }
    },
    methods: {
        descriptionParse(description) {
            var result = description ? description.replace('https://', '') : '-'
            return result.endsWith('/') ? result.slice(0, -1) : '-'
        },
        isApp() {
            if (process.env.VUE_APP_IS_APP == 'TRUE') {
                return true
            }
            return false
        },
        goToDashboard() {
            if (this.descriptionParse(this.dashboardLink) != '-') {
                window.open(this.dashboardLink, '_blank')
            }
        }
    }
}
</script>

<style lang="scss">
.control-panel-container {
    @include display-flex();
    @include flex-direction(column);
    .dashboard-title {
        @include font-size(xl);
        font-family: $text-bold;
        color: $neutro-s90;
        width: 100%;
        height: auto;
        text-align: center;
        padding-top: 40px;
        padding-bottom: 30px;
    }

    // SLIDER STYLES
    .images {
        @include display-flex();
        @include flex-direction(column);
        @include align-items(center);
        place-content: center;
        height: 100%;
        width: 100%;
        padding-bottom: 10px;

        .middle-title {
            @include font-size(l);
            font-family: $text-bold;
            color: $main-s30;
            padding-top: 15px;
            text-align: center;
            width: 90%;
            margin-bottom: 6px;
        }

        .description {
            @include font-size(m);
            font-family: $text-light;
            color: $main-s50;
            text-align: center;
            width: 90%;
        }

        &.img1 {
            .icon-top {
                @include background($image: img('group_primary_main_t50.svg'));
                width: rem(50px);
                height: rem(50px);
            }
        }
        &.img2 {
            .icon-top {
                @include background($image: img('menu_label_main_t50.svg'));
                width: rem(50px);
                height: rem(50px);
            }
        }
        &.img3 {
            .icon-top {
                @include background($image: img('menu_checklist_main_t50.svg'));
                width: rem(50px);
                height: rem(50px);
            }
        }
        &.img4 {
            .icon-top {
                @include background($image: img('mail_main_t50.svg'));
                width: rem(42px);
                height: rem(42px);
            }
        }
    }
    .background-hooper {
        @include background($image: img('ellipse_neutro_t90.svg'));
        @include display-flex();
        @include justify-content();
        @include align-self(center);
        padding: 30px;
        width: auto;
        height: auto;
        max-width: rem(450px);
        min-width: rem(350px);
        max-height: rem(300px);
        min-height: rem(200px);
    }
    .hooper {
        @include align-self(center);
        height: 25vh;
        width: 20vw;
        max-width: rem(450px);
        min-width: rem(350px);
        max-height: rem(250px);
        min-height: rem(200px);
    }
    .hooper:focus {
        outline: unset;
        background-clip: unset;

        .hooper-slide {
            outline: unset;
        }
    }
    .hooper-pagination {
        bottom: 10px;
    }
    .hooper-indicators {
        .hooper-indicator {
            @include background($image: img('slider_pagination.svg'));
            background-color: unset;
            width: 8px;
            height: 8px;

            &.is-active {
                @include background($image: img('slider_pagination_active.svg'));
                width: 8px;
                height: 8px;
                background-color: unset;
            }
        }
    }
    // END SLIDER STYLES

    .messages {
        padding-top: 20px;
    }

    .credentials {
        @include align-self(center);
        @include border-radius(8px);
        background-color: darken($default-bg-color, 5);
        margin-top: 28px;
        padding: 12px 20px;

        .title-credentials {
            @include font-size(m);
            font-family: $conden;
            color: $neutro-s90;
        }

        .link-access {
            @include display-flex();
            @include font-size(m);
            font-family: $text;
            color: $neutro-s90;
            padding-top: 6px;
            width: 100%;

            .link {
                @include font-size(m);
                font-family: $text-bold;
                color: $neutro-s90;
                padding-top: 6px;
                width: 100%;
            }
            &.text {
                min-width: 105px;
                max-width: 105px;
            }
        }
        .email-credential {
            @include display-flex();
            @include font-size(m);
            font-family: $text;
            color: $neutro-s90;
            padding-top: 6px;
            width: 100%;

            .email {
                @include font-size(m);
                font-family: $text-bold;
                color: $neutro-s90;
                padding-top: 6px;
                width: 110%;
            }

            &.text {
                min-width: 105px;
                max-width: 105px;
            }
        }
    }
    .btn {
        @include align-self(center);
        margin-top: 28px;
        width: auto;
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss" scoped>
.control-panel-container.portrait {
    .dashboard-title {
        @include font-size(ml);
        padding-top: 20px;
        padding-bottom: 10px;
        display: none;
    }

    .background-hooper {
        padding: 30px;
        max-width: 90vw;
        min-width: 60vw;
        max-height: 50vh;
        min-height: 30vh;
        width: 100%;
    }
    .hooper {
        @include align-self(center);
        height: 25vh;
        width: 20vw;
    }

    .images {
        width: 85%;
        margin: 0 auto;
        overflow: auto;

        .middle-title {
            @include font-size(sm);
            padding-top: 15px;
            width: 100%;
        }

        .description {
            @include font-size(s);
            font-family: $text-light;
            width: 100%;
        }
    }

    .credentials {
        margin-top: 15px;
        padding: 10px 15px;
        width: 90%;
        @include border-radius(4px);
        background-color: $default-sec-color;

        .title-credentials {
            @include font-size(s);
            font-family: $text-light;
        }

        .link-access {
            @include font-size(s);

            .link {
                @include font-size(s);
                font-family: $text-bold;
            }
            &.text {
                min-width: 75px;
                max-width: 75px;
            }
        }
        .email-credential {
            @include font-size(s);

            .email {
                @include font-size(xs);
                font-family: $text-bold;
            }

            &.text {
                min-width: 75px;
                max-width: 75px;
            }
        }
    }

    .btn {
        margin-top: 15px;
        width: auto;
        @include font-size(s);
    }
}
</style>

<template>
    <div id="sensors" :class="$mq">
        <SensorForm v-if="showForm" :sensorObject="sensorSelected" @cancel="hideForm"></SensorForm>

        <div class="search-container" v-if="!showForm">
            <input ref="searchBarSensor" class="search" type="text" @input="searchBarInput($event)" :value="search" />
            <div v-if="search != ''" class="close" @click="cleanSearch()"></div>
        </div>

        <div v-if="filteredSensors.length === 0" class="empty-page">
            <EmptyPage @buttonAction="newSensor()" customClass="sensors-config" :title="$t('config.sensor.empty_page_title')" :description="$t('config.sensor.empty_page_description')" :buttonText="$t('sensor.new_sensor')" buttonClass="link" />
        </div>

        <div class="sensors-container hide-scrollbar" v-if="!showForm">
            <sensor-card v-for="sensor in filteredSensors" :key="sensor.id" @click.native="selectSensor(sensor)" :sensor="sensor" />
        </div>

        <div v-if="!showForm && filteredSensors.length > 0 && userRoleLevelIsThreeOrMore" @click="newSensor" class="create-btn">
            {{ $t('sensor.new_sensor') }}
        </div>
    </div>
</template>

<script>
import SensorForm from '@/components/domain/config/forms/sensorForm.vue'
import SensorCard from '@/components/domain/sensor/sensorCard.vue'
import EmptyPage from '../../layout/EmptyPage.vue'

export default {
    name: 'sensor',
    components: { SensorCard, SensorForm, EmptyPage },
    data() {
        return {
            timerSearch: false,
            search: '',
            showForm: false,
            sensorSelected: false
        }
    },
    computed: {
        sensors() {
            return this.$store.getters['sensor/getList']()
        },
        filteredSensors() {
            if (this.sensors) {
                // filter templates by search
                if (this.search != '') {
                    //search by name and id

                    const filteredSensors = Object.values(this.sensors).filter((sensor) => {
                        return sensor.name.toLowerCase().includes(this.search.toLowerCase()) || sensor.ref.toLowerCase().includes(this.search.toLowerCase())
                    })

                    return filteredSensors
                } else {
                    const allSensors = Object.values(this.sensors)

                    return allSensors
                }
            }
        },
        userRoleLevelIsThreeOrMore() {
            const userInfo = this.$store.getters['loginUser/getUserInformation']
            const userRoleId = userInfo.rol

            const userRoleLevel = this.$store.getters['employee/getRol'](userRoleId).level

            return userRoleLevel >= 3
        }
    },
    methods: {
        searchBarInput(e) {
            const self = this
            if (this.timerSearch) {
                clearTimeout(this.timerSearch)
                this.timerSearch = null
            }
            this.timerSearch = setTimeout(() => {
                self.search = e.target.value
                // if (self.$mq != 'portrait') {
                //     self.selectedCat = self.categories['all']
                // }
            }, 800)
        },
        newSensor() {
            this.sensorSelected = false
            this.showForm = true
        },
        hideForm() {
            this.showForm = false
            this.sensorSelected = false
            // this.$overlay.hide()
        },
        selectSensor(sensor) {
            this.sensorSelected = sensor
            this.showForm = true
        },
        cleanSearch() {
            this.search = ''
        },
        load() {
            this.$store.dispatch('sensor/loadList').then(() => {})
        }
    },

    created() {
        this.load()

        if (this.$store.getters['sensor/getCreateState']) {
            this.sensorSelected = false
            this.showForm = true
            this.$store.commit('sensor/setCreateState', false)
        }
    }
}
</script>

<style lang="scss">
#sensors {
    width: 100%;
    height: 100%;
    padding: 10px;
    overflow: hidden;
    position: relative;

    .search-container {
        position: relative;
        width: 100%;
        .search {
            @include display-flex();
            @include align-items(center);
            @include background($image: img('search_neutro.svg'), $size: 25px, $position: left 12px center);
            @include border-radius(4px);
            @include font-size(ml);
            height: 45px;
            width: 100%;
            background-color: #fff;
            padding: 0 60px 0 45px;
            border-bottom: none;
            font-family: $text;
            cursor: text;
        }

        .close {
            @include border-radius(4px);
            @include background($image: img('close_dark.svg'), $size: 12px, $position: center center);
            width: 33px;
            height: 33px;
            position: absolute;
            top: 7px;
            right: 10px;
            background-color: $neutro-t50;
            cursor: pointer;
        }
    }

    .empty-page {
        height: calc(100% - 100px);
        display: grid;
        place-items: center;
    }

    .sensors-container {
        padding-top: 10px;
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(auto-fill, minmax(390px, 1fr));
        height: auto;
        max-height: calc(100% - 110px);
        overflow: scroll;
        grid-template-rows: 80px;
    }
    .create-btn {
        @include display-flex();
        @include align-items(center);
        @include align-self(center);
        @include justify-content(center);
        @include font-size(ml);
        @include border-radius(4px);
        width: fit-content;
        height: 50px;
        padding: 0 20px;
        background-color: $main;
        font-family: $text-bold;
        color: #fff;
        cursor: pointer;
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
    }

    &.portrait {
        padding: unset;

        .sensors-container {
            grid-template-columns: repeat(auto-fill, minmax(335px, 1fr));
            max-height: calc(100% - 110px) !important;
        }

        .create-btn {
            bottom: 0px;
            @include font-size(sm);
            background-color: $main-dark;
            font-size: 16px;
        }
    }
}
</style>

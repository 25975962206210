<template>
    <div class="form-container hide-scrollbar sensor-form" :class="[$mq]">
        <div class="form-title">
            {{ $t('sensor.vinculate_sensor') }}
        </div>
        <main class="form-content" v-if="sensor">
            <div class="field-form">
                <div class="label">
                    {{ $t('sensor.name_label') }}
                </div>
                <input type="text" v-model="sensor.name" :placeholder="$t('sensor.name_placeholder')" class="input" :class="{ error: errors.name }" />
            </div>
            <div class="field-form">
                <div class="label">
                    {{ $t('sensor.ref_number') }}
                </div>
                <input type="text" v-model="sensor.ref" class="input" :class="{ error: errors.name }" />
            </div>
            <div class="field-form">
                <div class="label">
                    {{ $t('sensor.status') }}
                </div>
                <select v-model="sensor.status" class="select" :class="[{ active: selectOpened }, { error: errors.rol }]" @click="selectOpened = !selectOpened">
                    <option v-for="status in statusOptions" :key="status.id" :value="status.value">{{ status.name }}</option>
                </select>
            </div>
            <div class="field-form in-line">
                <div class="label-alarm">
                    {{ $t('sensor.generate_alarm') }}
                </div>
                <label class="switch">
                    <input type="checkbox" v-model="generateAlarm" @click="toggleCheckbox" />
                    <div class="slider round"></div>
                </label>
            </div>
            <CollapseTransition>
                <div class="alert" v-if="generateAlarm">
                    <div class="field-form temperature">
                        <select v-model="sensor.config.condition" @input="selectCondition($event.target.value)" class="select temperature" :class="[{ active: selectOpened2 }, { error: errors.condition }]">
                            <option v-for="alarm in alarmOptions" :key="alarm.id" :value="alarm.value">{{ alarm.name }}</option>
                        </select>

                        <!-- input number for temperature -->
                        <input type="number" v-model="sensor.config.value" class="input temperature" :class="{ error: errors.value }" />
                        <div class="and" v-if="sensor.config.condition == '<>' || sensor.config.condition == '!<>'">
                            {{ $t('sensor.temperature_and') }}
                        </div>
                        <input type="number" v-if="sensor.config.condition == '<>' || sensor.config.condition == '!<>'" v-model="aditionalValue" class="input temperature" :class="{ error: errors.value }" />
                        <span class="text">ºC</span>
                    </div>

                    <div class="field-form">
                        <div class="label">
                            {{ $t('sensor.action_plan') }}
                        </div>
                        <select v-model="sensor.config.tpl_id" @change="$forceUpdate()" class="select" :class="[{ active: selectOpened }, { error: errors.rol }]" @click="selectOpened = !selectOpened">
                            <option v-for="actionPlan in actionPlans" :key="actionPlan.id" :value="actionPlan.id">{{ actionPlan.name }}</option>
                        </select>
                    </div>
                </div>
            </CollapseTransition>
        </main>
        <div class="footer-actions">
            <div v-if="sensorObject" class="btn remove" @click="removeSensor()"><img class="icon" src="~@/../public/img/delete_alert.svg" /> {{ $t('sensor.remove_sensor') }}</div>
            <div class="btns-actions">
                <div class="btn cancel" @click="cancel()">{{ $t('tickets.cancel') }}</div>
                <div class="btn" @click="validateSensor()">{{ $t('label.print.save') }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'
import { CollapseTransition } from '@ivanv/vue-collapse-transition'
export default {
    name: 'sensorForm',
    components: {
        CollapseTransition
    },
    props: {
        sensorObject: {
            type: [Object, Boolean],
            default: false
        }
    },
    data() {
        return {
            sensor: {
                name: '',
                ref: '',
                status: '',
                config: null
            },
            errors: {
                name: false,
                status: false,
                condition: false,
                tpl_id: false,
                value: false
            },
            aditionalValue: 0,
            selectOpened: false,
            selectOpened2: false,
            generateAlarm: false,
            alarmOptions: [
                {
                    id: 1,
                    name: i18n.t('sensor.greater_to'),
                    value: '>'
                },
                {
                    id: 2,
                    name: i18n.t('sensor.greater_equal_to'),
                    value: '>='
                },
                {
                    id: 3,
                    name: i18n.t('sensor.less_to'),
                    value: '<'
                },
                {
                    id: 4,
                    name: i18n.t('sensor.less_equal_to'),
                    value: '<='
                },
                {
                    id: 5,
                    name: i18n.t('sensor.equal_to'),
                    value: '='
                },
                {
                    id: 6,
                    name: i18n.t('sensor.not_equal_to'),
                    value: '!='
                },
                {
                    id: 7,
                    name: i18n.t('sensor.between'),
                    value: '<>'
                },
                {
                    id: 8,
                    name: i18n.t('sensor.not_between'),
                    value: '!<>'
                }
            ],
            statusOptions: [
                {
                    id: 1,
                    name: i18n.t('sensor.status_active'),
                    value: 1
                },
                // {
                //     id: 2,
                //     name: i18n.t('sensor.status_inactive'),
                //     value: 1
                // },
                {
                    id: 3,
                    name: i18n.t('sensor.status_paused'),
                    value: 0
                }
            ]
        }
    },
    computed: {
        actionPlans() {
            var items = this.$store.getters['issues/getTemplates']()
            let aItems = []
            //push all items to array including one empty item with id 0 and name 'ninguno'
            aItems.push({ id: 'none', name: i18n.t('sensor.no_action_plan') })
            for (var key in items) {
                aItems.push(items[key])
            }
            return aItems
        }
    },
    methods: {
        selectCondition(value) {
            let sensor = { ...this.sensor }
            sensor.config.condition = value
            this.sensor = sensor
        },
        toggleCheckbox() {
            this.generateAlarm = !this.generateAlarm
            if (this.generateAlarm) {
                this.sensor.config = {}
                this.sensor.config.alert = 1
                this.sensor.config.condition = '>'
                this.sensor.config.value = 0
                this.sensor.config.tpl_id = 'none'
            } else {
                this.sensor.config = null
            }
        },
        cancel() {
            this.$emit('cancel')
        },
        validateSensor() {
            this.$overlay.loading()
            if (fnCheckConnection()) {
                var sensor = this.sensor
                if (sensor.name == '') {
                    this.errors.name = this.$t('config.users.name_required')
                } else {
                    this.errors.name = false
                }
                if (sensor.ref == '') {
                    this.errors.ref = this.$t('sensor.ref_required')
                } else {
                    this.errors.ref = false
                }

                if (Object.values(this.errors).filter((error) => error != false).length > 0) {
                    this.$overlay.hide()
                    this.$snackbar.error({
                        duration: 10000,
                        closeable: true,
                        message: '<ul>' + Object.values(this.errors).filter(Boolean).join(' .', this.errors) + '</ul>'
                    })
                } else {
                    this.saveSensor()
                }
            } else {
                this.$snackbar.error({ duration: 10000, closeable: true, message: this.$t('config.users.no_internet') })
            }
        },
        saveSensor() {
            var self = this
            var hasToAddNewValue = false
            if (typeof this.sensor.config !== 'undefined' && this.sensor.config !== null) {
                if (this.sensor.config.condition == '<>' || this.sensor.config.condition == '!<>') {
                    hasToAddNewValue = this.sensor.config.value + ',' + this.aditionalValue
                }
            }
            if (this.sensorObject) {
                this.$store.dispatch('sensor/editSensor', { sensor: this.sensor, newVal: hasToAddNewValue }).then(function (response) {
                    if (response) {
                        self.$overlay.hide()
                        self.$emit('cancel')
                        self.$snackbar.success({ duration: 3000, closeable: true, message: self.$t('config.sensor.edit_success', { name: self.sensor.name }) })
                    }
                })
            } else {
                this.$store.dispatch('sensor/addSensor', { sensor: this.sensor, newVal: hasToAddNewValue }).then(function (response) {
                    if (response) {
                        self.$overlay.hide()
                        self.$emit('cancel')
                        self.$overlay.hide()
                        self.$snackbar.success({ duration: 3000, closeable: true, message: self.$t('config.sensor.add_success', { name: self.sensor.name }) })
                    }
                })
            }
        },
        removeSensor() {
            let self = this
            self.$popup.delete({
                message: self.$t('popup.confirm.remove_sensor', { name: self.sensor.name }),
                textSave: self.$t('popup.confirm.delete_sensor'),
                textCancel: self.$t('popup.confirm.text_cancel'),
                callSave: function () {
                    self.$overlay.loading()
                    self.$store
                        .dispatch('sensor/removeSensor', self.sensor.id)
                        .then(() => {
                            self.$popup.close()
                            self.$overlay.hide()
                            self.$snackbar.success({ duration: 3000, closeable: true, message: self.$t('config.sensor.remove_success', { name: self.sensor.name }) })
                            self.$emit('cancel')
                        })
                        .catch((error) => {
                            logError(error)
                            self.$overlay.hide()
                        })
                },
                callCancel: function () {
                    self.$popup
                        .close()

                        .catch((error) => {
                            logError(error)
                            self.$overlay.hide()
                        })
                }
            })
        }
    },
    created() {},
    mounted() {
        if (this.sensorObject) {
            this.sensor = { ...this.sensorObject }
            if (this.sensor.config != null && this.sensor.config.alert) {
                this.generateAlarm = true

                //cuando tenemos un valor adicional en el sensor
                if (typeof this.sensor.config.value == 'string') {
                    var aValues = this.sensor.config.value.split(',')
                    this.sensor.config.value = aValues[0]
                    this.aditionalValue = aValues[1]
                }
            }
        } else {
            this.sensor.status = 1
        }
        if (this.sensor.status == 2) {
            this.sensor.status = 1
        }
    }
}
</script>

<style lang="scss">
.form-container {
    .form-content {
        padding-top: 8px;
    }
    .field-form {
        .label-alarm {
            width: 100%;
            @include font-size(m);
            font-family: $text;
            color: $neutro-s60;
            text-align: left;
            max-width: 600px;
            padding: 9px 0px 6px 0px;
            width: calc(100% - 75px);
        }
        &.in-line {
            margin-top: 10px;
        }
        &.temperature {
            margin-top: 10px;
            @include flex-direction(row);
            @include justify-content(space-between);

            .and {
                margin-right: 6px;
            }
        }
    }
    .alert {
        width: 100%;
        height: 100%;
        max-width: 600px;
    }
}

.sensor-form {
    .footer-actions {
        gap: 8px;
    }

    &.portrait {
        .label,
        .input,
        .select,
        .label-alarm,
        .text,
        .btn,
        .btns-actions {
            font-size: 16px !important;
        }

        .form-title {
            font-size: 20px;
            padding: 8px 0px 0px 0px;
        }

        .footer-actions {
            // position: fixed;
            gap: 8px;
            // bottom: 0px;
            padding-top: 16px;
            background-color: #f4f2ed;
        }
    }
}
</style>

<template>
    <div class="sensor-container slideRight" :class="$mq">
        <div class="sensor-img"></div>
        <div class="description">
            <div class="sensor-name">
                {{ sensor.name }}
            </div>
            <div class="sensor-id">{{ $t('sensor.id') }} {{ sensor.ref }}</div>
            <div class="sensor-last-data">
                <div class="data" v-if="sensor.last_value">
                    {{ $t('sensor.last_data') }} {{ formatDate(sensor.last_value.date) }} {{ '| ' }}
                    <div class="temperature">
                        {{ sensor.last_value.value + 'ºC' }}
                    </div>
                </div>
                <div class="data" v-else>
                    {{ $t('sensor.last_data') }}
                    {{ $t('sensor.no_data') }}
                </div>
            </div>
        </div>
        <div class="status-img">
            <img v-if="sensor.status == 2" src="img/checked_ok.svg" />
            <img v-if="sensor.status == 1" src="img/offline/no_wifi.svg" />
            <img v-if="sensor.status == 0" src="img/pause-sensor.svg" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'sensor',
    props: {
        sensor: {
            type: Object,
            default: false
        }
    },
    data() {
        return {}
    },
    methods: {
        formatDate(date) {
            date = date * 1000
            return moment(date).format('DD/MM/YYYY HH:mm')
        }
    }
}
</script>

<style lang="scss">
.sensor-container {
    cursor: pointer;
    background-color: #fff;
    padding: 10px;
    height: 80px;
    @include display-flex();
    @include align-items(center);
    @include border-radius(4px);
    padding: 15px;

    &:hover {
        @include bgHover(#fff);
    }

    .sensor-img {
        width: 35px;
        height: 35px;
        min-width: 35px;
        min-height: 35px;
        margin-right: 6px;
        @include background($image: img('sensor-img.svg'), $size: 100%, $position: center);
    }
    .description {
        @include display-flex();
        @include flex-direction(column);
        width: calc(100% - 76px);
        .sensor-name {
            @include text-ellipsis();
            @include font-size(m);
            color: $inactive-s90;
            font-family: $text-sbold;
        }
        .sensor-id {
            @include text-ellipsis();
            @include font-size(sm);
            font-family: $text;
            color: $inactive-s90;
        }
        .sensor-last-data {
            .data {
                @include text-ellipsis();
                @include display-flex();
                @include align-items(center);
                @include font-size(sm);
                font-family: $text;
                color: $inactive-s60;

                .temperature {
                    padding-left: 4px;
                    @include font-size(s);
                    font-family: $text-sbold;
                    color: $inactive-s90;
                }
            }
        }
    }
    .status-img {
        min-width: 35px;
        min-height: 35px;
        width: 35px;
        height: 35px;
    }

    &.portrait {
        .description {
            .sensor-name {
                @include font-size(s);
            }
            .sensor-id {
                @include font-size(10);
            }
            .sensor-last-data {
                .data {
                    @include font-size(10);
                    font-family: $text;
                    color: $inactive-s60;

                    .temperature {
                        @include font-size(10);
                        font-family: $text-sbold;
                        color: $inactive-s90;
                    }
                }
            }
        }
    }
}
</style>

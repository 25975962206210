<template>
    <div class="user-page" :class="[$mq]">
        <div class="form-container" v-if="showForm">
            <Users-form :employeeLogged="employeeLogged" @cancel="cancel" :userObject="userSelected"></Users-form>
        </div>
        <div class="list-users" v-else :class="[$mq]">
            <div class="search-container">
                <input class="search" type="text" @input="searchBarInput($event)" :value="search" />
                <div v-if="search != ''" class="close" @click="cleanSearch()"></div>
            </div>
            <transition-group @before-leave="beforeLeave" class="users-list-container slideRight" name="list" tag="div">
                <employee :employee="employee" class="user-content" :rol="getRol(employee)" v-for="(employee, index) in filterEmployees" @click="selectUser(employee)" :key="index"></employee>
            </transition-group>
            <div class="create-btn" @click="createUser()">{{ $t('config.users.create') }}</div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import Message from '@/components/domain/ui/Message'
import employee from '@/components/domain/login/employee'
import UsersForm from '@/components/domain/config/forms/usersForm'
export default {
    components: { Message, employee, UsersForm },
    name: 'Users',
    data() {
        return {
            search: '',
            showForm: false,
            userSelected: false
        }
    },
    computed: {
        employeeLogged() {
            var idEmployee = this.$store.getters['loginUser/getLocalEmployee']
            return this.$store.getters['employee/getEmployee'](idEmployee)
        },
        employees() {
            var employees = this.$store.getters['employee/getAllEmployees']
            var result = []
            if (this.employeeLogged) {
                var rolLoggedUser = this.$store.getters['employee/getRol'](this.employeeLogged.rol)
                for (var employee in employees) {
                    var idx = employees[employee]
                    const level = this.$store.getters['employee/getRol'](idx.rol).level
                    if (rolLoggedUser.level >= level && idx.password) {
                        if(level <=3){
                            result.push(idx)
                        }
                    }
                }
            }
            return result
        },
        filterEmployees() {
            var employees = []
            if (this.employees) {
                employees = this.employees.filter((employee) => {
                    return cleanString(employee.name.toLowerCase()).includes(cleanString(this.search.toLowerCase())) || cleanString(employee.surname.toLowerCase()).includes(cleanString(this.search.toLowerCase()))
                })
                employees.sort(function (a, b) {
                    if (cleanString(a.name).toLowerCase() < cleanString(b.name).toLowerCase()) {
                        return -1
                    } else if (cleanString(a.name).toLowerCase() > cleanString(b.name).toLowerCase()) {
                        return 1
                    } else if (cleanString(a.surname).toLowerCase() < cleanString(b.surname).toLowerCase()) {
                        return -1
                    } else {
                        return 1
                    }
                })
            }
            return employees
        }
    },
    methods: {
        beforeLeave(el) {
            const { marginLeft, marginTop, width, height } = window.getComputedStyle(el)
            el.style.left = `${el.offsetLeft - parseFloat(marginLeft, 50)}px`
            el.style.top = `${el.offsetTop - parseFloat(marginTop, 50)}px`
            el.style.width = width
            el.style.height = height
        },
        cancel() {
            this.showForm = false
            this.userSelected = false
        },
        selectUser(employee) {
            this.userSelected = { ...employee }
            this.showForm = true
        },
        getRol(employee) {
            if (employee.rol) {
                return this.$store.getters['employee/getRol'](employee.rol)
            } else {
                return false
            }
        },
        isApp() {
            if (process.env.VUE_APP_IS_APP == 'TRUE') {
                return true
            }
            return false
        },
        searchBarInput(e) {
            const self = this
            if (this.timerSearch) {
                clearTimeout(this.timerSearch)
                this.timerSearch = null
            }
            this.timerSearch = setTimeout(() => {
                self.search = e.target.value
            }, 600)
        },
        cleanSearch() {
            this.search = ''
        },
        createUser() {
            this.showForm = true
        }
    }
}
</script>
// PORTRAIT STYLES
<style lang="scss" scoped>
.user-page {
    @include display-flex();
    @include flex-direction(column);
    width: 100%;
    height: 100%;
    overflow: hidden;
    .form-container {
        width: 100%;
        height: 100%;
    }
    .list-users {
        @include display-flex();
        @include flex-direction(column);
        width: 100%;
        height: 100%;
        overflow: hidden;
        .search-container {
            position: relative;
            width: 100%;
            margin: 0;
            padding-bottom: 15px;
            .search {
                @include display-flex();
                @include align-items(center);
                @include background($image: img('search_neutro.svg'), $size: 25px, $position: left 12px center);
                @include border-radius(4px);
                @include font-size(ml);
                height: 45px;
                width: 100%;
                background-color: #fff;
                padding: 0 60px 0 45px;
                border-bottom: none;
                font-family: $text;
                cursor: text;
            }
            .close {
                @include border-radius(4px);
                @include background($image: img('close_dark.svg'), $size: 12px, $position: center center);
                width: 33px;
                height: 33px;
                position: absolute;
                top: 7px;
                right: 20px;
                background-color: $neutro-t50;
                cursor: pointer;
            }
        }
        .users-list-container {
            @include display-flex();
            @include justify-content(flex-start);
            @include flex-wrap(wrap);
            @include align-items(flex-start);
            @include align-content(flex-start);
            gap: 10px;
            width: 100%;
            height: calc(100% - 60px - 45px);
            overflow-y: auto;
            .user-content {
                @include display-flex();
                flex: 0 1 auto;
                width: calc(100% - 10px);
                margin-bottom: 0px;
                width: 100%;
            }
        }
        .create-btn {
            @include display-flex();
            @include align-items(center);
            @include align-self(center);
            @include justify-content(center);
            @include font-size(ml);
            @include border-radius(4px);
            width: fit-content;
            height: 50px;
            margin-top: 10px;
            padding: 0 20px;
            background-color: $main-dark;
            font-family: $text-bold;
            color: #fff;
            cursor: pointer;
        }
        &.landscape {
            width: 100%;
            height: 100%;
            padding: 10px;
            .user-content {
                height: 60px;
                flex: 0 1 calc(33% - 5px);
            }
        }
        &.desktop {
            width: 100%;
            height: 100%;
            padding: 10px;
            .user-content {
                height: 60px;
                flex: 0 1 calc(33% - 5px);
            }
        }
        @media screen and (max-width: 999px) and (min-width: 580px) {
            .users-list-container {
                .user-content {
                    flex: 0 1 calc(50% - 5px);
                }
            }
        }
    }
}
</style>

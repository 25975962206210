<template>
    <div v-if="config" class="printer" :class="[$mq]">
        <div class="content">
            <template v-if="is_current_printer">
                <template v-if="completed">
                    <!-- <Message v-if="sdk == 'brother' && !config.template_loaded" :message="$t('config.printer.errors.tpl_loader_0')" type="error" :buttonLabel="$t('config.printer.config')" @buttonAction="reset()"></Message> -->

                    <Message v-if="config && !config.mode" :message="$t('config.printer.alert')" :buttonLabel="$t('config.printer.config_go')" @buttonAction="reset()" type="warning"></Message>
                    <Message v-else-if="printerError" :loader="checkingStatusPrinter" :message="printerError" type="error" :buttonLabel="$t('config.printer.validate')" @buttonAction="validatePrinter"></Message>
                    <!-- <Message v-else-if="!config.template_id && sdk == 'brother'" :loader="checkingStatusPrinter" :message="$t('config.printer.alert')" type="error" :buttonLabel="$t('config.printer.validate')" @buttonAction="validatePrinter"></Message> -->
                </template>

                <!-- IF HASN'T GOT A CONFIGURATION -->
                <template v-else>
                    <Message :message="$t('config.printer.alert')" :buttonLabel="$t('config.printer.config_go')" @buttonAction="updateOption()" type="warning"></Message>
                </template>
            </template>

            <div class="printer-box" :class="{ notavailable: false, nosetted: !completed || printerError }">
                <template v-if="['portrait'].includes($mq) && is_current_printer">
                    <div class="header">
                        <div v-if="is_current_printer" class="current">
                            {{ $t('config.printer.active') }}
                        </div>
                    </div>
                </template>
                <div class="container" @click="updateOption()">
                    <div class="col left">
                        <img class="image" :src="show && printerModels[config.model] ? printerModels[config.model].image : css_image_url + 'printer_cccccc.svg'" />
                    </div>
                    <div class="col right">
                        <template v-if="show">
                            <div class="info">
                                <div class="row name" :class="{ 'hide-status': !is_current_printer }">
                                    {{ config.name }}
                                </div>
                                <li class="row">
                                    <template v-if="['landscape', 'desktop'].includes($mq)">
                                        <span v-if="printerModels[config.model]" class="label">{{ $t('config.printer.modelname') }} </span>
                                    </template>
                                    <span class="value bold"> {{ printerModels[config.model].name }}</span>
                                </li>
                                <li class="row">
                                    <template v-if="['landscape', 'desktop'].includes($mq)">
                                        <span class="label">{{ $t('config.printer.connection') }} </span>
                                    </template>
                                    <span class="value"> {{ ['ALL', 'NET'].includes(config.mode) ? (config.print_mode ? config.print_mode.toUpperCase() : 'WIFI') : config.mode }}</span>
                                </li>
                                <li class="row" v-if="config.mode == 'WIFI' || (['ALL', 'NET'].includes(config.mode) && config.print_mode == 'wifi')">
                                    <template v-if="['landscape', 'desktop'].includes($mq)">
                                        <span class="label">{{ $t('config.printer.ip') }} </span>
                                    </template>
                                    <span class="value"> {{ config.ip }}</span>
                                </li>
                                <li class="row" v-if="config.mode == 'BLUETOOTH' || (config.mode == 'ALL' && config.print_mode == 'bluetooth')">
                                    <template v-if="['landscape', 'desktop'].includes($mq)">
                                        <span class="label">{{ $t('config.printer.mac') }} </span>
                                    </template>
                                    <span class="value">{{ config.mac }}</span>
                                </li>
                                <li class="row" v-if="config.mode == 'ALL' && config.ssid">
                                    <template v-if="['landscape', 'desktop'].includes($mq)">
                                        <span class="label">{{ $t('config.printer.ssid') }} </span>
                                    </template>
                                    <span class="value"> {{ config.ssid }}</span>
                                </li>
                                <template v-if="!isApp">
                                    <!-- WINDOWS -->
                                    <li class="row" v-if="config.path">
                                        <template v-if="['landscape', 'desktop'].includes($mq)">
                                            <span class="label">{{ $t('config.printer.path') }} </span>
                                        </template>
                                        <span class="value">{{ config.path }}</span>
                                    </li>
                                </template>
                            </div>
                        </template>
                        <template v-else>
                            <!-- NO CONFIGURATION -->
                            <div class="info error">
                                <div class="row name">
                                    {{ $t('config.printer.no_configured') }}
                                </div>
                            </div>
                        </template>
                    </div>
                </div>

                <div class="footer" :class="{ iscurrent: is_current_printer }">
                    <template v-if="['landscape', 'desktop'].includes($mq)">
                        <div v-if="is_current_printer" class="current">
                            {{ $t('config.printer.active') }}
                        </div>
                    </template>
                    <!-- NEED TO UPDATE PRINTER -->
                    <div class="buttons">
                        <template v-if="update">
                            <button class="btn update" @click="updateOption()">
                                {{ $t('config.printer.update') }}
                            </button>
                        </template>
                        <template v-else>
                            <button class="btn test" @click="printTest()" :class="{ disabled: !completed, loading: checkingStatusPrinter }" :disabled="!completed">
                                {{ $t('config.printer.test.action') }}
                            </button>
                            <button class="btn reset" @click="updateOption()" :class="{ test: !completed }">
                                {{ $t('config.printer.config') }}
                            </button>

                            <button v-if="completed" class="btn more" @click="toggleOptions($event.target)">‎</button>
                            <div class="more-options">
                                <button v-if="!is_current_printer" class="btn current" @click="setCurrent()">
                                    {{ $t('menu_aux.action.set_current') }}
                                </button>
                                <button class="btn delete" @click="deleteOption()">
                                    {{ $t('menu_aux.action.delete') }}
                                </button>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Message from '@/components/domain/ui/Message'
import * as bpac from '@/../public/js/bpac.js'
import mixin from '@/core/mixin'

export default {
    components: { Message },
    mixins: [mixin],
    props: {
        config: { type: Object },
        is_current_printer: { type: Boolean, default: false }
    },
    data() {
        let sdk = 'brother'
        return {
            moreOptions: false,
            sdk: 'brother',
            update: false,
            bpacStatus: { status: true, error: '' }
        }
    },
    computed: {
        completed() {
            // Printer is correctly configured (boolean)
            if (this.isApp && this.config && this.sdk == 'brother' && this.config.print_mode == 'wifi') return true
            return (this.isApp && this.config && this.config.platform == cordova.platformId && (this.sdk == 'brother' || this.sdk == 'zebra')) || (!this.isApp && this.config.platform == 'windows') || (this.printerModels[this.config.model].sdk == 'zebra' && (this.config.mode == 'WIFI' || (this.config.mode == 'ALL' && this.config.print_mode == 'wifi')))
            return (this.isApp && this.config && this.config.platform == 'android' && (this.sdk == 'brother' || this.sdk == 'zebra')) || (!this.isApp && this.config.platform == 'windows' && this.bpacStatus.status)
        },
        show() {
            // Printer configuration should be shown (boolean)
            if (this.isApp && this.config && this.sdk == 'brother' && this.config.print_mode == 'wifi') return true
            return (this.isApp && this.config && this.config.platform == cordova.platformId && (this.sdk == 'brother' || this.sdk == 'zebra')) || (!this.isApp && this.config.platform == 'windows') || (this.printerModels[this.config.model].sdk == 'zebra' && (this.config.mode == 'WIFI' || (this.config.mode == 'ALL' && this.config.print_mode == 'wifi')))
        },

        checkingStatusPrinter() {
            return this.$store.getters['printer/isCheckingStatusPrinter']
        },

        printerError() {
            // return false
            // console.log('---printerError---')
            if (this.$store.getters['printer/getStatusPrinter']) {
                return false
            } else {
                var oError = this.$store.getters['printer/getStatusErrorPrinter']
                var msg = this.$t(oError.msg, { s: oError.code })
                msg = msg.replace('[path]', this.config.path)
                return msg
            }
        },

        printerModels() {
            return this.$store.getters['printer/getModels']()
        },

        isApp() {
            return this.$store.getters['getIsApp']
        }
    },
    methods: {
        toggleOptions(element) {
            // GET ALL "3 DOTS MORE" BUTTONS
            var divs = document.getElementsByClassName('btn more')
            for (var i = 0; i < divs.length; i++) {
                // GET RELATIVE FROM "3 DOT MORE" BUTTON THE "MORE-OPTIONS" DIV
                var moreOptions = divs[i].parentElement.getElementsByClassName('more-options')[0]
                // ON THE CLICKED BUTTON TOGGLE CLASS
                if (divs[i] === element) {
                    if (moreOptions) moreOptions.classList.toggle('active')
                }
                // ELSE CLOSE THE REST OF "MORE-OPTIONS" DIVS
                else {
                    moreOptions.classList.remove('active')
                }
            }
        },
        deleteOption() {
            this.moreOptions = false
            this.$emit('delete')
        },

        setCurrent() {
            var self = this
            this.$store.dispatch('printer/setCurrentPrinter', this.config.id).then(() => {
                self.validatePrinter()
                document.getElementById('config_content_scroll').scrollTo({ top: 0, behavior: 'smooth' })
                self.$snackbar.progress({
                    closeable: true,
                    message: self.$t('config.printer.change_current', { s: this.config.name })
                })
            })
        },

        updateOption() {
            this.moreOptions = false
            this.$emit('update')
        },
        validatePrinter() {
            var self = this
            self.$overlay.loading()
            if (!this.isApp) {
                this.$store.dispatch('printer/validateBpac', this.config.id).then(function (response) {
                    self.bpacStatus = response
                    self.$overlay.hide()
                })
            } else {
                this.$store.dispatch('printer/statusPrinter').then(function (response) {
                    self.$overlay.hide()
                })
            }
        },

        printTest() {
            var self = this
            this.moreOptions = false
            if (this.config) {
                self.$overlay.loading()
                var label_info = []
                this.$store
                    .dispatch('printer/printLabelTest', { printData: label_info, num_copies: 1, config_id: this.config.id })
                    .then(function () {
                        var msg = self.isApp ? self.$tc('config.printer.success_print', 1) : self.$t('tooltip.windows_print')
                        if (self.isApp) {
                            msg = msg.replace('[product]', self.$t('config.printer.test.label_msg'))
                        }
                        self.$snackbar.progress({
                            closeable: true,
                            message: msg
                        })

                        self.$overlay.hide()
                    })
                    .catch(function (e) {
                        log('printTest error - ', e)
                        var msg
                        if (typeof e === 'string') {
                            msg = e == 'config.printer.error_print' ? self.$tc(e, 1) : self.$t(e)
                        } else if (!e.msg) {
                            msg = self.$tc('config.printer.error_print', 1)
                        } else {
                            msg = self.$t(e.msg)
                        }
                        msg = msg.replace('[product]', self.$t('config.printer.test.label_msg'))
                        self.$snackbar.error({ closeable: true, message: msg })
                        self.validatePrinter()
                        self.$overlay.hide()
                    })
            }
        }
    },

    created() {
        var self = this
        if (this.isApp) {
            self.is_searching = true
            // if (self.config.template_id && self.config.template_loaded == 1) {
            // self.validatePrinter()
            // }
        }
    },
    mounted() {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#content.config {
    .printer {
        margin-bottom: 25px;

        .content {
            width: 90%;
            margin: 0 auto;
            max-width: 800px;

            .printer-box {
                @include display-flex();
                @include justify-content();
                @include align-items();
                box-shadow: 0px 0px 9px rgba(221, 221, 221, 0.3);
                overflow: visible;
                width: 100%;
                background-color: #fff;
                @include border-radius(6px);
                margin-top: 30px;
                flex-wrap: wrap;

                .container {
                    @include display-flex();
                    padding: 10px;
                    @include background($image: img('right_neutro.svg'), $size: 10px, $position: right 15px center);
                    cursor: pointer;
                    width: 100%;

                    .col {
                        &.left {
                            @include display-flex();
                            @include justify-content();
                            @include align-items();
                            width: 150px;
                            height: 100%;
                            height: 150px;

                            .image {
                                // width: 100%;
                                width: 100px;
                            }
                        }
                        &.right {
                            width: calc(100% - 150px);
                        }

                        .info {
                            padding: 15px 15px;
                            .row {
                                margin-bottom: 9px;
                                list-style: none;
                                &.name {
                                    @include font-size(l);
                                    font-family: $text-bold;
                                    margin-bottom: 20px;
                                    @include background($image: img('checked_ok.svg'), $size: 20px, $position: left center);
                                    padding-left: 30px;
                                    color: $neutro-s90;

                                    &.hide-status {
                                        background-image: none;
                                        padding-left: 0px;
                                    }
                                }
                                .label {
                                    @include font-size(m);
                                    width: 150px;
                                    min-width: 150px;
                                    font-family: $text;
                                    display: inline-block;
                                    color: $neutro-s90;
                                }
                                .value {
                                    @include font-size(m);
                                    font-family: $text-bold;
                                    color: $neutro-s90;
                                    width: calc(100% - 150px);
                                    min-width: 200px;
                                    display: inline-block;
                                    font-weight: bold;
                                }
                            }

                            &.error {
                                .row {
                                    &.name {
                                        @include background($image: img('error_alert.svg'), $size: 20px, $position: left center);

                                        &.hide-status {
                                            background-image: none;
                                            padding-left: 0px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .footer {
                    @include display-flex();
                    @include justify-content(flex-end);
                    @include align-items();
                    @include flex-wrap(wrap);
                    background-color: $neutro-t70;
                    width: 100%;
                    text-align: right;
                    padding: 10px 10px;

                    .current {
                        // @include align-self(flex-start);
                        @include font-size('sm');
                        margin: 3px 5px;
                        max-width: 250px;
                        // padding: 10px 15px;
                        height: 45px;
                        @include background($image: img('star_main.svg'), $size: 13px, $position: left center);
                        // padding: 3px 6px 3px 20px;
                        padding-left: 20px;
                        line-height: 45px;
                        min-width: 0;
                        color: $main;
                        font-family: $conden-bold;
                        width: max-content;
                        float: left;
                    }

                    .buttons {
                        @include display-flex();
                        @include justify-content(flex-end);
                        @include align-items();
                        @include flex-wrap(wrap);
                        position: relative;

                        .btn {
                            @include font-size(16);
                            margin: 3px 5px;
                            max-width: 250px;
                            padding: 10px 15px;
                            height: auto;
                            min-width: 40px;
                            min-height: 40px;
                            cursor: pointer;

                            &.reset {
                                background-color: $main-t80;
                                color: $main;

                                &:hover {
                                    background-color: $neutro-t60;
                                }
                            }
                            &.delete {
                                @include background($image: img('delete_ffffff.svg'), $size: 20px, $position: left 5px center);
                                background-color: $error;
                                color: $neutro-light;
                                padding-left: 30px;
                                &:hover {
                                    background-color: $error-dark;
                                }
                            }
                            &.current {
                                @include background($image: img('star_main.svg'), $size: 20px, $position: left 5px center);
                                background-color: $main-t80;
                                color: $main;
                                font-family: $text-bold !important;
                                line-height: 16px;
                                padding-left: 30px;
                                min-height: 45px;
                                &:hover {
                                    background-color: $neutro-t50;
                                }
                            }
                            &.more {
                                @include background($image: img('more_vert_primary.svg'), $size: 20px, $position: center center);
                                background-color: $main-t80;
                                width: 40px;
                                &:hover {
                                    background-color: $neutro-t50;
                                }
                            }
                            &.test {
                                background-color: $main;
                                color: #fff;
                                &:hover {
                                    background-color: $main-t20;
                                }
                            }
                            &.loading {
                                @include background($image: img('loader_light.svg'), $position: center center, $size: 40px);
                                pointer-events: none;
                                text-indent: -99999px;
                                animation: none;
                            }
                            &:hover {
                                background-color: $main-t20;
                            }
                        }
                        .more-options {
                            border-radius: 4px;
                            background-clip: padding-box;
                            @include align-items();
                            position: absolute;
                            right: 0px;
                            bottom: -54px;
                            z-index: 20;
                            padding: 3px 3px 0 3px;
                            background-color: #fff;
                            display: none;
                            align-content: center;

                            &.active {
                                @include display-flex();
                                @include justify-content();
                                @include align-items();
                                @include flex-direction(column);
                            }

                            .btn {
                                width: 100%;
                            }
                        }
                    }

                    &.iscurrent {
                        @include justify-content(space-between);
                    }
                }

                &.notavailable {
                    cursor: not-allowed;
                    width: 500px;
                    margin: 30px auto;
                    height: 100px;

                    .col {
                        &.left {
                            width: 120px;
                            .image {
                                width: 60px;
                            }
                        }

                        &.right {
                            width: 100% !important;
                            @include display-flex();
                            @include align-items();
                            height: 100%;

                            .info {
                                .row {
                                    &.name {
                                        @include background($image: img('error_alert.svg'), $size: 20px, $position: left center);
                                        margin: 0;

                                        &.hide-status {
                                            background-image: none;
                                            padding-left: 0px;
                                        }
                                    }
                                }
                            }
                        }
                        .footer {
                            .btn {
                                &.reset {
                                    background-color: $main;
                                    color: #fff;
                                }
                                &.test {
                                    cursor: not-allowed;
                                    background-color: $inactive;
                                    color: $inactive-s30;
                                    pointer-events: none;
                                }
                            }
                        }
                    }
                }
                // WHEN IS SOMETHING WRONG
                &.nosetted {
                    .col {
                        .info {
                            .row {
                                &.name {
                                    @include background($image: img('error_alert.svg'), $size: 20px, $position: left center);

                                    &.hide-status {
                                        background-image: none;
                                        padding-left: 0px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            @include small {
                .printer-box {
                    .col {
                        &.left {
                            width: 120px;
                            .image {
                                width: 75px;
                            }
                        }
                        &.right {
                            width: calc(100% - 120px);

                            .name {
                                @include font-size($size: ml, $important: true);
                            }

                            .label {
                                @include font-size($size: sm, $important: true);
                            }
                            .value {
                                @include font-size($size: sm, $important: true);
                            }
                        }
                    }
                    .footer {
                        width: 100%;
                        .btn {
                            width: auto;
                        }
                    }
                }
            }

            @media (max-width: 900px) {
                .printer-box {
                    .footer {
                        &.iscurrent {
                            .current {
                                height: 30px;
                                line-height: 32px;
                            }
                            .buttons {
                                width: 100%;
                            }
                        }
                        // .buttons {
                        //     .more-options {
                        //         right: 45px;
                        //         bottom: 0px;
                        //     }
                        // }
                    }
                }
            }

            @media (max-width: 800px) {
                .printer-box {
                    .footer {
                        .buttons {
                            .more-options {
                                right: 45px;
                                bottom: 0px;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss" scoped>
#content.config.portrait {
    .printer {
        margin-bottom: 15px;

        .content {
            width: 100% !important;
            max-width: none;
            height: 100% !important;
            // padding-bottom: 150px;
            max-height: 100%;
        }
        .printer-box {
            margin-top: 15px;

            .header {
                width: 100%;
                height: 40px;
                line-height: 40px;
                background-color: $neutro;
                padding: 0 15px;

                .current {
                    @include font-size(s);
                    @include background($image: img('star_main.svg'), $size: 13px, $position: left center);
                    padding-left: 18px;
                    color: $main;
                    font-family: $conden-bold;
                }
            }
            .container {
                @include display-flex();
                @include align-items();
                .col {
                    height: fit-content;

                    &.left {
                        width: 75px;
                        // height: 100px;
                        height: 100%;
                        .image {
                            width: 50px;
                        }
                    }
                    &.right {
                        width: calc(100% - 75px);
                    }

                    .info {
                        padding: 10px;

                        .row {
                            height: auto;
                            margin-bottom: 0px;

                            &.name {
                                @include font-size($size: s, $important: true);
                                margin-bottom: 10px;

                                &.hide-status {
                                }
                            }
                            .label {
                            }
                            .value {
                                @include font-size($size: xs, $important: true);
                                font-family: $text;
                                &.bold {
                                    font-family: $text-bold;
                                }
                            }
                        }

                        &.error {
                            .row {
                                &.name {
                                    &.hide-status {
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .footer {
                padding: 2px;

                .buttons {
                    @include justify-content(flex-end);
                    @include flex-wrap(nowrap);
                    position: relative;
                    padding: 5px 0;
                    width: 100%;

                    .btn {
                        @include font-size(xs);
                        margin: 3px 5px;
                        max-width: none;
                        padding: 10px 10px;
                        @include text-ellipsis();

                        &.more {
                            @include background($image: img('more_vert_primary.svg'), $size: 17px, $position: center center);
                        }
                    }
                    .more-options {
                        border-radius: 4px;
                        background-clip: padding-box;
                        @include align-items();
                        position: absolute;
                        right: 0px;
                        bottom: -54px;
                        z-index: 20;
                        padding: 3px 3px 0 3px;
                        background-color: #fff;
                        display: none;

                        &.active {
                            @include display-flex();
                        }
                    }
                }
            }
        }
    }
}
</style>
